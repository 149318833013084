import colors from './colors';
import DiagramGroup from './diagramGroup';
import DiagramText from './diagramText';

const drawTableText = () => {
  let _text = new DiagramText();
  _text.family = 'Commissioner';
  _text.fill = colors.BLACK;
  _text.size = 21;
  return _text;
};

const drawTableRectangle = context => {
  let _rect = context.two.makeRectangle(0, 0, 0, 0);
  _rect.fill = context.diagram.transparent ? colors.WHITE : colors.BACKGROUND;
  _rect.stroke = colors.BLACK;
  return _rect;
};

const drawTableLine = context => {
  let _rect = context.two.makeRectangle(0, 0, 0, 2);
  _rect.fill = colors.BLACK;
  _rect.noStroke();
  return _rect;
};

const drawWarnLine = context => {
  let _rect = context.two.makeRectangle(0, 0, 0, 2);
  _rect.noStroke();
  return _rect;
};

class TableGroup extends DiagramGroup {
  constructor(id, context) {
    super(id, context);
    this.erText = drawTableText();
    this.erRect = drawTableRectangle(this.context);
    this.erWarn = drawWarnLine(this.context);
    this.erLine = drawTableLine(this.context);
    this.erColumns = this.context.two.makeGroup();
    this.add(this.erRect, this.erLine, this.erColumns, this.erText, this.erWarn);
  }
  getColumns() {
    return this.erColumns;
  }
  getShape() {
    return { x: this.translation.x, y: this.translation.y, width: this.erRect.width, height: this.erRect.height };
  }
  setName(name) {
    this.erText.value = name;
    const modelScene = this.context.diagram.scene.$model;
    const textWidth = this.erText.getBoundingClientRect().width / modelScene.scale;
    this.erWarn.width = textWidth;
  }
  setDimensions() {
    const modelScene = this.context.diagram.scene.$model;
    const textWidth = this.erText.getBoundingClientRect().width / modelScene.scale;
    const width = Math.max(180, textWidth + 80, ...this.erColumns.children.map(c => c.erText.getBoundingClientRect().width / modelScene.scale + 60));
    const height = this.erColumns.children.length * 28 + 100;
    this.erRect.width = width;
    this.erRect.height = height;
    this.erText.translation.set(0, -height / 2 + 30);
    this.erWarn.translation.set(0, -height / 2 + 42);
    this.erLine.width = width;
    this.erLine.translation.set(0, -height / 2 + 56);
    this.erColumns.translation.set(-width / 2, -height / 2);
  }
  setSelected(selected) {
    this.erText.weight = selected ? 600 : 400;
    this.erRect.linewidth = selected ? 3 : 2;
    this.erLine.height = selected ? 3 : 2;
    this.erWarn.height = selected ? 4 : 2;
  }
  setWarningsVisible(visible, errors) {
    this.erWarn.fill = errors ? colors.RED : colors.YELLOW;
    this.erWarn.opacity = visible ? 1 : 0;
  }
}

export const drawTable = (table, context) => {
  const scene = context.diagram.scene;
  let _table = context.diagram.$(table.getId());
  
  const warningsAllowed = !context.diagram.transparent;
  const tableErrors = table.getErrors().filter(e => !e.scope || e.scope == context.diagram.step);
  const tableWarnings = table.getWarnings().filter(w => !w.scope || w.scope == context.diagram.step);
  
  if(!_table) {
    _table = new TableGroup(table.getId(), context);
    context.diagram.register(table.getId(), _table);
    scene.$modelInner.add(_table);
  }
  
  _table.setName(table.getName());
  _table.setPosition(table.getX(), table.getY());
  _table.setDimensions();
  _table.setSelected(context.diagram.selectedItem?.getId() == table.getId());
  _table.setWarningsVisible(warningsAllowed && (tableErrors.length || tableWarnings.length), tableErrors.length);
};