export const bound = (value, min, max) => {
  return Math.min(max, Math.max(min, value));
};
export const getTouch = (list, id) => {
  for(let i = 0; i < list.length; i++) {
    if(list.item(i).identifier == id)
      return list.item(i);
  }
};
export const generateUid = () => {
  const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let array = new Uint32Array(8);
  window.crypto.getRandomValues(array);
  return Array(...array).map(x => alphabet[x % alphabet.length]).join('');
};