import colors from './colors';
import DiagramGroup from './diagramGroup';
import DiagramText from './diagramText';
import { multipleIdentifiersCurve, externalIdentifierCurve } from '../../utils/erModel';

const drawEntityText = () => {
  let _text = new DiagramText();
  _text.family = 'Commissioner';
  _text.fill = colors.BLACK;
  _text.size = 21;
  return _text;
};

const drawEntityRectangle = context => {
  let _rect = context.two.makeRectangle(0, 0, 0, 40);
  _rect.fill = colors.TRANSPARENT;
  _rect.linewidth=0;
  _rect.noStroke();
  return _rect;
};

const drawWarnLine = context => {
  let _rect = context.two.makeRectangle(0, 12, 0, 2);
  _rect.noStroke();
  return _rect;
};

const drawMultiIdCurve = (context, curvePoints) => {
  let _curve = context.two.makeCurve(...curvePoints, true);
  _curve.fill = colors.TRANSPARENT;
  _curve.stroke = colors.BLACK;
  _curve.linewidth = 2;
  return _curve;
};

const drawExternalIdCurve = (context, roundRect, outerCircleRadius, sa, ea) => {
  let _curve = context.two.makeRoundedRectangle(0, 0, roundRect.width, roundRect.height, roundRect.radius);
  _curve.fill = colors.TRANSPARENT;
  _curve.stroke = colors.BLACK;
  _curve.linewidth = 2;
  let _mask = context.two.makeArcSegment(0, 0, 0, outerCircleRadius, sa, ea);
  let _group = context.two.makeGroup(_curve);
  _group.mask = _mask;
  return _group;
};

const drawIdCircle = (context, x, y, radius) => {
  let _circle = context.two.makeCircle(x, y, radius);
  _circle.fill = colors.BLACK;
  _circle.stroke = colors.BLACK;
  return _circle;
};

class EntityGroup extends DiagramGroup {
  constructor(id, context) {
    super(id, context);
    this.erText = drawEntityText();
    this.erRect = drawEntityRectangle(this.context);
    this.erWarn = drawWarnLine(this.context);
    this.erMultiIdCirclesContainer = this.context.two.makeGroup();
    this.erMultiIdContainer = this.context.two.makeGroup(this.erMultiIdCirclesContainer);
    this.erExternalIdCirclesContainer = this.context.two.makeGroup();
    this.erExternalIdContainer = this.context.two.makeGroup(this.erExternalIdCirclesContainer);
    this.erAttrs = this.context.two.makeGroup();
    this.erGeneralization = this.context.two.makeGroup();
    this.add(this.erAttrs, this.erGeneralization, this.erRect, this.erMultiIdContainer, this.erExternalIdContainer, this.erRect, this.erText, this.erWarn, /*this.erRect2*/);
  }
  getAttrs() {
    return this.erAttrs;
  }
  getGeneralization() {
    return this.erGeneralization;
  }
  getShape() {
    return { x: this.translation.x, y: this.translation.y, width: this.erRect.width, height: this.erRect.height };
  }
  setName(name) {
    this.erText.value = name;
    const modelScene = this.context.diagram.scene.$model;
    const textWidth = this.erText.getBoundingClientRect().width / modelScene.scale;
    this.erRect.width = Math.max(50, textWidth + 50);
    this.erWarn.width = textWidth;
  }
  setIdentifiers(attributes) {
    this.erMultiIdContainer.opacity = attributes.length > 1 ? 1 : 0;
    this.erMultiIdContainer.remove(this.erMultiIdCurve);
    if(attributes.length > 1) {
      const curvePoints = multipleIdentifiersCurve(attributes, 25, 4);
      this.erMultiIdCurve = drawMultiIdCurve(this.context, curvePoints);
      this.erMultiIdCirclesContainer.remove(this.erMultiIdCirclesContainer.children);
      for(let i = 1; i < curvePoints.length / 2; i++)
        this.erMultiIdCirclesContainer.add(drawIdCircle(this.context))
      this.erMultiIdContainer.add(this.erMultiIdCurve);
    }
  }
  setExternalIdentifierAttributes(attributes) {
    this.erExtIdAttrs = attributes;
    this.erExtIdRelationships = [];
    this.erExternalIdContainer.opacity = 0;
  }
  addExternalIdentifierRelationship(_relationship) {
    this.erExtIdRelationships.push(_relationship);
    this.erExternalIdContainer.opacity = 1;
    this.erExternalIdContainer.remove(this.erExternalIdCurve);
    const [roundRect, outerCircleRadius, sa, ea, points] = externalIdentifierCurve(this, this.erExtIdRelationships, this.erExtIdAttrs, 18, 8, 20);
    this.erExternalIdCurve = drawExternalIdCurve(this.context, roundRect, outerCircleRadius, sa, ea);
    this.erExternalIdCirclesContainer.remove(this.erExternalIdCirclesContainer.children);
    for(let i = 0; i < points.length / 2; i++)
        this.erExternalIdCirclesContainer.add(drawIdCircle(this.context, points[i*2], points[i*2 + 1], (i == points.length / 2 - 1) ? 8 : 4));
    this.erExternalIdContainer.add(this.erExternalIdCurve);
    
    this.erExternalIdCurve.children[0].stroke = this.erToBeTranslated ? colors.GRAY73 : colors.BLACK;
    for(let externalIdCircle of this.erExternalIdCirclesContainer.children)
      externalIdCircle.fill = externalIdCircle.stroke = this.erToBeTranslated ? colors.GRAY73 : colors.BLACK;
  }
  setSelected(selected) {
    this.erText.weight = selected ? 600 : 400;
    this.erRect.linewidth = selected ? 3 : 2;
    this.erWarn.height = selected ? 4 : 2;
  }
  setWarningsVisible(visible, errors) {
    this.erWarn.fill = errors ? colors.RED : colors.YELLOW;
    this.erWarn.opacity = visible ? 1 : 0;
  }
  setToBeTranslated(toBeTranslated) {
    this.erToBeTranslated = toBeTranslated;
    this.erRect.stroke = toBeTranslated ? colors.TRANSPARENT : colors.TRANSPARENT;
    if(this.erMultiIdCurve)
      this.erMultiIdCurve.stroke = toBeTranslated ? colors.GRAY73 : colors.BLACK;
    for(let multiIdCircle of this.erMultiIdCirclesContainer.children)
      multiIdCircle.fill = multiIdCircle.stroke = toBeTranslated ? colors.GRAY73 : colors.BLACK;
  }
}

export const drawEntity = (entity, context) => {
  const scene = context.diagram.scene;
  let _entity = context.diagram.$(entity.getId());

  const entityAttributes = entity.getAttributes();
  
  const warningsAllowed = !context.diagram.transparent;
  const entityErrors = entity.getErrors().filter(e => !e.scope || e.scope == context.diagram.step);
  const entityWarnings = entity.getWarnings().filter(w => !w.scope || w.scope == context.diagram.step);
  
  const translating = context.diagram.step == 'erTranslation';

  if(!_entity) {
    _entity = new EntityGroup(entity.getId(), context);
    context.diagram.register(entity.getId(), _entity);
    scene.$modelInner.add(_entity);
  }

  _entity.setName(entity.getName());
  _entity.setPosition(entity.getX(), entity.getY());
  _entity.setIdentifiers(entityAttributes.filter(a => a.isIdentifier()));
  _entity.setExternalIdentifierAttributes(entityAttributes.filter(a => a.isExternalIdentifier()));
  _entity.setSelected(context.diagram.selectedItem?.getId() == entity.getId());
  _entity.setWarningsVisible(warningsAllowed && (entityErrors.length || entityWarnings.length), entityErrors.length);
  
  _entity.setToBeTranslated(translating);
};