<template>
  <DiagramEditor contextName="erDesign" :store="store">
    <div v-if="!showNotes">
      <div><ToolboxButton text="Notes" icon="into_left" :selected="showNotes" @click="mainStore.toggleNotesDataMenu()" class="notesButton"/></div>
    </div>
    <div v-if="showNotes">
      <div><ToolboxButton text="Hide" icon="into_right" @click="mainStore.toggleNotesDataMenu()" class="notesButton" /></div>
      <div class="my-editor"><editor spellcheck="false" @change="readEditor($event)" :value="readData()"/></div>
    </div>    
    <ERModelEditorToolbox />
    <ZoomButtons />
    <ErrorStatusBar :errors="selectedItemErrors" :warnings="selectedItemWarnings" />
    <div class="info" v-if="['connect', 'parent'].includes(selectedTool)">
      🖍️
      <template v-if="selectedTool == 'connect'">Select an element to be connected with the {{getElement()}} operator</template>
      <template v-if="selectedTool == 'parent'">Select an entity to be the parent of the current entity.</template>
    </div>
  </DiagramEditor> 
</template>

<script>
import { useToast } from 'vue-toastification';

import DiagramEditor from './DiagramEditor';
import ERModelEditorToolbox from './ERModelEditorToolbox';
import ErrorStatusBar from './ErrorStatusBar';
import ZoomButtons from './ZoomButtons.vue';
import { getContext } from '../lib/editor';
import { mainStore } from '../store/main';
import ToolboxButton from './ToolboxButton.vue';
import { quillEditor } from 'vue3-quill'// require styles
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

export default {
  name: 'ERModelEditor',
  components: {
    DiagramEditor,
    ERModelEditorToolbox,
    ErrorStatusBar,
    ZoomButtons,
    ToolboxButton,
    'editor': quillEditor,
  },
  setup() {
    const toast = useToast();

    const store = mainStore.erDesign;
    const selectedTool = store.$selectedTool();
    const selectedItemWarnings = store.$selectedItemWarnings();
    const selectedItemErrors = store.$selectedItemErrors();
    const erDesignStore = mainStore.erDesign;
    const showNotes = mainStore.$showNotesDataMenu();  

    getContext('erDesign').setCallbacks({
      undo: () => mainStore.undo(),
      redo: () => mainStore.redo(),
      selectTool: tool => store.selectTool(tool),
      addItem: (x, y) => store.addItem(x, y),
      selectItem: id => {
        const res = store.selectItem(id);
        if(res !== true)
          toast.error(res, { timeout: 5000 });
      },
      moveItem: (dx, dy) => store.moveItem(dx, dy),
      endMoveItem: () => store.finishedMovingItem(),
      deleteItem: () => store.deleteItem(),
    })

    function getElement(){
      let draw;
      switch(erDesignStore.getSelectedItem().getTypeOfInPut()){
      case 'unary': 
        draw='Select'
      break;
      case 'result': 
        draw='Result';
      break;
      case 'unaryPi': 
        draw='Project';
      break;
      case 'binary': 
        draw='Theta-Join';
      break;
      case 'naturalJoin': 
        draw='Natural Join';
      break;
      case 'semi-join': 
        draw='Semi-Join';
      break;
      case 'asemi-join': 
        draw='Anti Semi-Join';
      break;
      case 'difference': 
        draw='Difference';
      break;
      case 'division': 
        draw='Division';
      break;
      case 'Union': 
        draw='Union';
      break;
      case 'intersection': 
        draw='Intersection';
      break;
      }
      return draw;
    }
    function readEditor(e){
      mainStore.setEditorData(e);
    }
    function readData(){  
      return mainStore.getEditorData();
    }

    return {
      store,
      selectedTool,
      selectedItemWarnings,
      selectedItemErrors,
      getElement,
      showNotes,
      readEditor,
      readData,
      mainStore,
    };
  }
};
</script>

<style scoped>
.info {
  background: #cbcee4;
}
.notesButton {
  position: absolute;
  right: 0px;
  top: 0px;
  font-size: 2em;
  text-align: center;
  visibility: visible; 
}
.my-editor{
  position: absolute;
  right: 0px;
  top: 60px;
  width: 500px;
  height: 100%;
  background: white;
}
</style>