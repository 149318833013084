import colors from './colors';
import DiagramGroup from './diagramGroup';
import DiagramText from './diagramText';
import { participationSegment } from '../../utils/erModel';
import Entity from '../../entity';

const drawParticipationLine = (context, x1, y1, x2, y2) => {
  let _line = context.two.makeLine(x2, y2, x1, y1);
  _line.stroke = colors.BLACK;
  _line.linewidth = 2;
  return _line;
};

const drawSelectionLine = (context, x1, y1, x2, y2) => {
  let _line = context.two.makeLine(x1, y1, x2, y2);
  _line.stroke = colors.TRANSPARENT;
  _line.linewidth = 12;
  return _line;
};

const drawText = () => {
  let _text = new DiagramText('');
  _text.family = 'Commissioner';
  _text.fill = colors.BLACK;
  _text.size = 18;
  return _text;
};

const drawTextBackground = context => {
  let _rect = context.two.makeRectangle(0, 0, 0, 24);
  _rect.fill = context.diagram.transparent ? colors.WHITE : colors.BACKGROUND;
  _rect.noStroke();
  return _rect;
};

class ParticipationGroup extends DiagramGroup {
  constructor(id, context) {
    super(id, context);
    this.erSegment = null;
    this.erLineContainer = this.context.two.makeGroup();
   
    this.erRoleText = drawText();
    this.erRoleTextBackground = drawTextBackground(this.context);
    this.temp='';
    this.comment=drawText();
  
    this.add(this.erLineContainer, this.erRoleTextBackground, this.erRoleText, this.temp, this.comment);
  }
  setLine2(_entity, _relationship, unary) {
    const [x1, y1, x2, y2] = participationSegment(_entity, _relationship, unary);
    this.erSegment = { entityX: x1, entityY: y1, relationshipX: x2, relationshipY: y2 };
    this.erLineContainer.remove(this.erLine).remove(this.erSelectionLine).remove(this.erLine2);
    this.erLine = drawParticipationLine(this.context, x1, y1, x2, y2);
    this.erLine.position.set(-10,0);
    this.erLine2 =  drawParticipationLine(this.context, x1, y1, x2, y2);
    this.erLine2.position.set(10,0);
    this.comment.position.set(0,0);
    this.comment.size=15;
    this.erLineGroup = this.context.two.makeGroup(this.erLine, this.erLine2, this.comment);
    this.erLineGroup.position.set(0,0);

    this.erSelectionLine = drawSelectionLine(this.context, x1, y1, x2, y2);
    this.erSelectionLinew = drawSelectionLine(this.context, x1, y1, x2, y2);

    this.erLineContainer.add(this.erLine).add(this.erSelectionLine).add(this.erLine2).add(this.erSelectionLine2).add(this.comment);
   
    this.erRoleText.translation.set((x1 + x2*2) / 3, (y1 + y2*2) / 3 + 2);
    this.comment.translation.set((x1 + x2*2) / 3, (y1 + y2*2) / 3 + 2);

    this.erRoleTextBackground.translation.set((x1 + x2*2) / 3, (y1 + y2*2) / 3);
    
  }

  setLine(_entity, _relationship, unary) {
    let [x1, y1, x2, y2] = participationSegment(_entity, _relationship, unary);
    //y1 è di sotto
    y2+=10;
    if(_relationship.getP2()){
      y2+=16;
    }
    if(_relationship.getP1()){
      y2+=16;
    }
    if(_relationship.getP3()){
      y2+=16;
    }
    this.erSegment = { entityX: x1, entityY: y1, relationshipX: x2, relationshipY: y2};
    this.erLineContainer.remove(this.erLine).remove(this.erSelectionLine).remove(this.erLine2);
    this.erLine = drawParticipationLine(this.context, x1, y1, x2, y2); //y1 è di sopra 


    this.erSelectionLine = drawSelectionLine(this.context, x1, y1, x2, y2);
    this.erSelectionLinew = drawSelectionLine(this.context, x1, y1, x2, y2);

    this.erLineContainer.add(this.erLine).add(this.erSelectionLine);
    
    this.erRoleText.translation.set((x1 + x2*2) / 3, (y1 + y2*2) / 3 + 2);
    this.comment.translation.set((x1 + x2*2) / 3 + 10, (y1 + y2*2) / 3 + 2);
  

    this.erRoleTextBackground.translation.set((x1 + x2*2) / 3, (y1 + y2*2) / 3);
  }
  
  setRole(role) {
    this.erRoleText.value = role;
    this.erRoleText.opacity = role.length ? 1 : 0;
    const modelScene = this.context.diagram.scene.$model;
    this.erRoleTextBackground.width = role.length ? this.erRoleText.getBoundingClientRect().width / modelScene.scale + 6 : 0;
  }
  setComment(comment){
    //console.log(comment);
    if(comment!='')
      this.comment.value = "ⓘ";
      this.comment.size=15;
  }
  setSelected(selected) {
    this.erLine.linewidth = selected ? 4 : 2;
    this.erRoleText.weight = selected ? 600 : 400;
  }
  setSelected2(selected) {
    this.erLine.linewidth = selected ? 4 : 2;
    this.erLine2.linewidth = selected ? 4 : 2;
    this.erRoleText.weight = selected ? 600 : 400;    
  }
  setTemporary(rel){    
    if(!(rel instanceof Entity)){
      //console.log(rel.getTempAttrs());
      this.temp=rel.getTempAttrs();
    }
  }
  getTemporary(){
    return this.temp;
  }
  getSupportedFunctionalities() {
    return {
      tempSchema: true,
    };
  }  
}
export const drawParticipation = (participation, context) => {
  const scene = context.diagram.scene;
  let _participation = context.diagram.$(participation.getId());
  //console.log(_participation);
  const _et = context.diagram.$(participation.getGenericET().getId());
  //console.log( participation.getGenericET().getId());
  const _relationship = context.diagram.$(participation.getRelationship().getId());
  if(!_participation) {
    _participation = new ParticipationGroup(participation.getId(), context);
    context.diagram.register(participation.getId(), _participation);
    scene.$modelParticipations.add(_participation);
  }

  participation.getRelationship().getTypeOfInPut()=='result' ? _participation.setLine2(_et, _relationship, participation.getUnaryIndex()) : _participation.setLine(_et, _relationship, participation.getUnaryIndex());
  _participation.setRole(participation.getRole());
  participation.getRelationship().getTypeOfInPut()=='result' ? _participation.setSelected2(context.diagram.selectedItem?.getId() == participation.getId()): _participation.setSelected(context.diagram.selectedItem?.getId() == participation.getId());
  
  /*if(participation.isExternalIdentifier())
    _et.addExternalIdentifierRelationship(_relationship);*/
  /*  let input;
  if(participation.getGenericET().__type=='Entity'){
    input=participation.getGenericET().getAttributes();
  }
  else{
    input=participation.getRelationship().getTempAttrs();
  }
  _participation.setTemporary(input, participation.getRelationship());*/
  _participation.setTemporary(participation.getGenericET());
  _participation.setComment(participation.getComment());
};