import { centroid } from '../utils/erModel';

export const collapseGeneralizationIntoParent = function(model, generalizationId) {
  const generalization = model.getItemById(generalizationId);
  const parentEntity = generalization.getEntity();
  const childrenRelationships = [...new Set(generalization.getChildren().flatMap(gc => gc.getEntity().getParticipations()).map(p => p.getRelationship()))];
  const childEntitiesIds = new Set(generalization.getChildren().map(gc => gc.getEntity().getId()));
  const relationshipsAmongChildren = childrenRelationships.filter(r => r.getParticipations().map(p => p.getEntity().getId()).filter(i => childEntitiesIds.has(i)).length > 1).map(r => r.getId());
  for(let generalizationChild of generalization.getChildren()) {
    const childEntity = generalizationChild.getEntity();
    for(let attribute of childEntity.getAttributes()) {
      const genAttribute = model.addAttribute(attribute.getName(), parentEntity.getId(), false, false, `0_${attribute.getMaxCardinality()}`);
      for(let subattribute of attribute.getSubattributes())
        model.addAttribute(subattribute.getName(), genAttribute.getId());
      model.deleteItem(attribute.getId());
    }
    for(let participation of childEntity.getParticipations()) {
      let participationRole = participation.getRole();
      if(!participationRole && relationshipsAmongChildren.includes(participation.getRelationship().getId()))
        participationRole = childEntity.getName().toLowerCase();
      model.addParticipation(parentEntity.getId(), null, participation.getRelationship().getId(), `0_${participation.getMaxCardinality()}`, false, participationRole);
      model.deleteItem(participation.getId());
    }
    model.deleteItem(childEntity.getId());
  }
  model.addAttribute('Type', parentEntity.getId());
};

export const collapseGeneralizationIntoChildren = function(model, generalizationId) {
  const generalization = model.getItemById(generalizationId);
  const parentEntity = generalization.getEntity();
  for(let generalizationChild of generalization.getChildren()) {
    const childEntity = generalizationChild.getEntity();
    for(let attribute of parentEntity.getAttributes()) {
      const genAttribute = model.addAttribute(attribute.getName(), childEntity.getId(), attribute.isIdentifier(), attribute.isExternalIdentifier(), attribute.getCardinality());
      for(let subattribute of attribute.getSubattributes())
        model.addAttribute(subattribute.getName(), genAttribute.getId());
    }
    for(let relationship of parentEntity.getParticipations().map(p => p.getRelationship())) {
      const participations = relationship.getParticipations();
      const genRelationshipPosition = centroid(participations.map(p => p.getEntity().getId() == parentEntity.getId() ? childEntity : p.getEntity()));
      const genRelationship = model.addRelationship(relationship.getName(), genRelationshipPosition.x, genRelationshipPosition.y);
      for(let participation of participations) {
        const isChildParticipation = participation.getEntity().getId() == parentEntity.getId();
        model.addParticipation(
          isChildParticipation ? childEntity.getId() : participation.getEntity().getId(),
          null,
          genRelationship.getId(),
          isChildParticipation ? participation.getCardinality() : `0_${participation.getMaxCardinality()}`,
          participation.isExternalIdentifier(),
          participation.getRole()
        );
      }
    }
  }
  for(let participation of parentEntity.getParticipations())
    model.deleteItem(participation.getRelationship().getId());
  model.deleteItem(parentEntity.getId());
};

export const substituteGeneralization = function(model, generalizationId) {
  const generalization = model.getItemById(generalizationId);
  const parentEntity = generalization.getEntity();
  for(let generalizationChild of generalization.getChildren()) {
    const childEntity = generalizationChild.getEntity();
    const relationshipPosition = centroid([parentEntity, childEntity]);
    const relationship = model.addRelationship(
      `IS_${childEntity.getName()}`,
      relationshipPosition.x,
      relationshipPosition.y
    );
    model.addParticipation(parentEntity.getId(), null, relationship.getId(), '0_1', false, '');
    model.addParticipation(childEntity.getId(), null, relationship.getId(), '1_1', true, '');
  }
  model.deleteItem(generalization.getId());
};