export const translateEntity = function(model, entityId) {
  const entity = model.getItemById(entityId);
  const table = model.addTable(entity.getName(), entity.getX(), entity.getY(), entity.wasGeneratedFromMultivaluedAttribute());
  if(entity.hasKey()) {
    let columnIndex = 0;
    for(let participation of entity.getParticipations()) {
      model.addParticipation(null, table.getId(), participation.getRelationship().getId(), participation.getCardinality(), participation.isExternalIdentifier(), participation.getRole());
      model.deleteItem(participation.getId());
    }
    for(let attribute of entity.getAttributes().filter(a => a.isIdentifier()))
      model.addColumn(attribute.getName(), table.getId(), columnIndex++, true, false);
    for(let attribute of entity.getAttributes().filter(a => !a.isIdentifier()))
      model.addColumn(attribute.getName(), table.getId(), columnIndex++, false, attribute.getMinCardinality() == '0');
  } else {
    let columnIndex = 0;
    let itemsToDelete = [];
    for(let participation of entity.getParticipations()) {
      if(participation.isExternalIdentifier()) {
        for(let column of participation.getRelationship().getParticipations().find(p => !!p.getTable()).getTable().getColumns().filter(c => c.isIdentifier())) {
          const columnName = `${column.getTable().getName()}_${column.getName()}${participation.getRole() ? '_' + participation.getRole() : ''}`;
          const genColumn = model.addColumn(columnName, table.getId(), columnIndex++, true, false);
          model.addForeignKey(genColumn.getId(), column.getId());
        }
        itemsToDelete.push(participation.getRelationship().getId());
      } else {
        model.addParticipation(null, table.getId(), participation.getRelationship().getId(), participation.getCardinality(), false, participation.getRole());
        itemsToDelete.push(participation.getId());
      }
    }
    for(let attribute of entity.getAttributes().filter(a => a.isExternalIdentifier()))
      model.addColumn(attribute.getName(), table.getId(), columnIndex++, true, false);
    for(let attribute of entity.getAttributes().filter(a => !a.isExternalIdentifier()))
      model.addColumn(attribute.getName(), table.getId(), columnIndex++, false, attribute.getMinCardinality() == '0');
    for(let itemId of itemsToDelete)
      model.deleteItem(itemId);
  }
  model.deleteItem(entity.getId());
};