<template>
  <div class="menu toolbox">
    <div><ToolboxButton text="Undo" icon="undo" :disabled="!canUndo" @click="mainStore.undo()" /></div>
    <div><ToolboxButton text="Redo" icon="redo" :disabled="!canRedo" @click="mainStore.redo()" /></div>
    <div><ToolboxButton text="Reset" icon="reset" @click="mainStore.resetTranslation()" /></div>
    <div v-if="selectedItem">
      <div class="sep"></div>
      <div class="submenu-container" v-if="supported.translating">
        <div><ToolboxButton text="Fix" icon="fix" :errorTooltip="(isEntity && !canEntityBeTranslated) ? 'This entity depends on other entities which have not been translated yet.' : 'All entities must be translated before proceeding with relationships.'" :disabled="(isEntity && !canEntityBeTranslated) || (!isEntity && modelContainsEntities)" :selected="showOneToManyTypeBRelationshipMenu || showOneToOneTypeBRelationshipMenu || showOneToOneTypeCRelationshipMenu" @click="store.translateItem()" /></div>
        <div class="submenu-align">
          <div class="submenu" v-if="showOneToManyTypeBRelationshipMenu">
            <div><ToolboxButton text="Into (0,1)" icon="into_left" @click="store.translateOneToManyTypeBRelationship('into_01')" /></div>
            <div><ToolboxButton text="Table" icon="new_table" @click="store.translateOneToManyTypeBRelationship('table')" /></div>
          </div>
        </div>
        <div class="submenu-align">
          <div class="submenu" v-if="showOneToOneTypeBRelationshipMenu">
            <div><ToolboxButton text="Into A" icon="into_left" @click="store.translateOneToOneTypeBRelationship('into_first')" /></div>
            <div><ToolboxButton text="Into B" icon="into_right" @click="store.translateOneToOneTypeBRelationship('into_second')" /></div>
          </div>
        </div>
        <div class="submenu-align">
          <div class="submenu" v-if="showOneToOneTypeCRelationshipMenu">
            <div><ToolboxButton text="Into A" icon="into_left" @click="store.translateOneToOneTypeCRelationship('into_first')" /></div>
            <div><ToolboxButton text="Into B" icon="into_right" @click="store.translateOneToOneTypeCRelationship('into_second')" /></div>
            <div><ToolboxButton text="Table A" icon="new_table" @click="store.translateOneToOneTypeCRelationship('table_first')" /></div>
            <div><ToolboxButton text="Table B" icon="new_table" @click="store.translateOneToOneTypeCRelationship('table_second')" /></div>
          </div>
        </div>
      </div>
      <div class="rename-container" v-if="supported.renaming">
        <ToolboxButton text="Rename" icon="rename" :selected="showRenamingInput" @click="store.toggleMenu('renaming')" />
        <div class="rename-input-container" v-if="showRenamingInput">
          <input type="text" spellcheck="false" placeholder="New name" v-focusselect="showRenamingInput" @input="renameItem($event)" @keydown="onRenameKeydown($event)" />
        </div>
      </div>
    </div>
    <div class="sep"></div>
    <div><ToolboxButton text="Design" icon="design" @click="mainStore.backToDesign()" /></div>
    <div><ToolboxButton text="Done" icon="done" errorTooltip="Translation must be complete before proceeding." :disabled="!canFinalize" @click="mainStore.moveToSchemaDesign()" /></div>
  </div>
</template>

<script>
import { computed } from 'vue';

import ToolboxButton from './ToolboxButton';

import Entity from '../lib/entity';
import { mainStore } from '../store/main';

export default {
  name: 'ERModelTranslateToolbox',
  components: {
    ToolboxButton
  },
  directives: {
    focusselect: {
      mounted(el) {
        el.focus();
        el.select();
      }
    }
  },
  setup() {
    const erTranslationStore = mainStore.erTranslation;
    const canUndo = mainStore.$canUndo();
    const canRedo = mainStore.$canRedo();
    const selectedItem = erTranslationStore.$selectedItem();
    const showRenamingInput = erTranslationStore.$renamingInput();
    const showOneToManyTypeBRelationshipMenu = erTranslationStore.$oneToManyTypeBRelationshipMenu();
    const showOneToOneTypeBRelationshipMenu = erTranslationStore.$oneToOneTypeBRelationshipMenu();
    const showOneToOneTypeCRelationshipMenu = erTranslationStore.$oneToOneTypeCRelationshipMenu();
    const modelContainsEntities = erTranslationStore.$modelContainsEntities();
    const canFinalize = erTranslationStore.$canFinalize();
    
    const supported = computed(() => selectedItem.value.getSupportedFunctionalities());
    const isEntity = computed(() => selectedItem.value instanceof Entity);
    const canEntityBeTranslated = computed(() => selectedItem.value instanceof Entity && selectedItem.value.canBeTranslated());
    
    function renameItem(e) {
      erTranslationStore.renameItem(e.target.value);
      e.target.value = erTranslationStore.getRenamingValue();
    }
    function onRenameKeydown(e) {
      if(e.which == 13 || e.which == 27)
        erTranslationStore.toggleMenu();
    }
    
    return {
      store: erTranslationStore,
      mainStore,
      canUndo,
      canRedo,
      selectedItem,
      showRenamingInput,
      showOneToManyTypeBRelationshipMenu,
      showOneToOneTypeBRelationshipMenu,
      showOneToOneTypeCRelationshipMenu,
      modelContainsEntities,
      canFinalize,
      supported,
      isEntity,
      canEntityBeTranslated,
      renameItem,
      onRenameKeydown
    };
  }
};
</script>