<template>
  <div class="er-translation-container">
    <div class="translation flex flex-col">
      <div class="header"><PanelTitle>Translation</PanelTitle></div>
      <ERModelTranslate />
    </div>
  </div>
</template>

<script>
import ERModelTranslate from './ERModelTranslate';
import PanelTitle from './PanelTitle';

export default {
  name: 'ERTranslation',
  components: {
    ERModelTranslate,
    PanelTitle
  }
};
</script>

<style scoped>
.er-translation-container {
  display: flex;
  height: 100%;
  overflow: hidden;
}
.translation {
  flex: 1;
  padding: 10px 20px;
}
.header {
  color: #aaa;
}
</style>