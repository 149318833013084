<template>
  <div class="errors" v-if="errors?.length">
    ⛔ {{ errors[0].description }}&ensp;
    <span v-if="errors.length > 1">(and {{ errors.length - 1 }} more)</span>
  </div>
  <div class="warnings" v-if="(!errors || !errors.length) && warnings?.length">
    ⚠️ {{ warnings[0].description }}&ensp;
    <span v-if="warnings.length > 1">(and {{ warnings.length - 1 }} more)</span>
  </div>
</template>

<script>
export default {
  name: 'ErrorStatusBar',
  props: ['errors', 'warnings']
};
</script>

<style scoped>
.errors {
  background: #e6472e;
  color: #fff;
}
.warnings {
  background: #ecd50e;
}
.errors span, .warnings span {
  font-size: 11px;
}
</style>