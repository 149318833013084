import Attribute from './attribute';
import Column from './column';
import Entity from './entity';
import ForeignKey from './foreignKey';
import Generalization from './generalization';
import GeneralizationChild from './generalizationChild';
import Participation from './participation';
import Relationship from './relationship';
import Table from './table';
import columnTypes from './columnTypes';
import * as restrComposite from './restructuring/compositeAttribute';
import * as restrGeneralization from './restructuring/generalization';
import * as restrMultivalued from './restructuring/multivaluedAttribute';
import * as transEntity from './translation/entity';
import * as transManyToMany from './translation/manyToManyRelationship';
import * as transOneToMany from './translation/oneToManyRelationship';
import * as transOneToOne from './translation/oneToOneRelationship';
//import { idealAttributePosition } from './utils/erModel';
//import BinaryInput from './binaryInput';

const itemClasses = {
  Attribute,
  Column,
  Entity,
  ForeignKey,
  Generalization,
  GeneralizationChild,
  Participation,
  Relationship,
 // BinaryInput,
  Table
};

export default class Model {
  constructor(uid = 1, erCode = '', sqlCode = '', items = []) {
    this.uid = uid;
    this.uid_t=1; //id table
    this.uid_s = 1;  //id del predicato
    this.uid_p= 1; //id natural join
    this.uid_j = 1; //id  difference
    this.uid_d = 1; //id division
    this.uid_i = 1; //id intersection
    this.uid_u =1; //id union
    this.uid_result = 1;
    this.uid_attr=1;
    this.erCode = erCode;
    this.sqlCode = sqlCode;
    this.itemsArray = [];
    this.itemsMap = {};

    for(let item of items) {
      this.itemsArray.push(item);
      this.itemsMap[item.getId()] = item;
    }
  }
  static fromObject(obj) {
    let items = obj.itemsArray || [];
    let model = new Model(obj.uid, null, null, []);
    for(let item of items){
       // console.log(item);
       // console.log(item.__type);
       // console.log(model);
       // console.log(itemClasses);
        model._addItem(itemClasses[item.__type].fromObject(model, item));
    }
    return model;
  }

  getUid(){
    return this.uid;
  }
  getUidT(){
    return this.uid_t;
  }
  getUidS(){
    return this.uid_s;
  }
  getUidP(){
    return this.uid_p;
  }
  getUidJ(){
    return this.uid_j;
  }
  getUidD(){
    return this.uid_d;
  }
  getUidI(){
    return this.uid_i;
  }
  getUidU(){
    return this.uid_u;
  }
  getUidAttr(){
    return this.uid_attr;
  }
  _uid() {
    return this.uid++;
  }
  _uid_t() {
    return this.uid_t++;
  }
  _uid_s() {
    return this.uid_s++;
  }
  _uid_p() {
    return this.uid_p++;
  }
  _uid_j() {
    return this.uid_j++;
  }
  _uid_d() {
    return this.uid_d++;
  }
  _uid_i() {
    return this.uid_i++;
  }
  _uid_u() {
    return this.uid_u++;
  }
  _uid_attr() {
    return this.uid_attr++;
  }
  _addItem(item) {
    this.itemsArray.push(item);
    this.itemsMap[item.getId()] = item;
    return item;
  }
  _deleteItem(id) {
    const i = this.itemsArray.findIndex(i => i.getId() == id);
    if(i != -1)
      this.itemsArray.splice(i, 1);
    delete this.itemsMap[id];
  }
  getERCode() {
    return this.erCode;
  }
  getSQLCode() {
    return this.sqlCode;
  }
  getItems() {
    return this.itemsArray;
  }
  getItemById(id) {
    return id ? this.itemsMap[id] : null;
  }
  getItemWhere(filter) {
    return this.itemsArray.find(filter) || null;
  }
  getItemsWhere(filter) {
    return this.itemsArray.filter(filter);
  }
  isEmpty() {
    return this.itemsArray.length == 0;
  }
  hasErrors() {
    return this.itemsArray.some(i => i.getErrors && i.getErrors().length);
  }
  needsRestructuring() {
    return this.itemsArray.some(i => i.getSupportedFunctionalities().restructuring);
  }
  needsTranslation() {
    return this.itemsArray.some(i => i.getSupportedFunctionalities().translating);
  }
  setERCode(code) {
    this.erCode = code;
  }
  setSQLCode(code) {
    this.sqlCode = code;
  }
  addEntity(name, x, y, item) {
    const id = this._uid();
    const id_t=this._uid_t();
    name = name || `Table${id_t}`;
    
    if(item){
      //name.replace(/\s/g, '_').replace(/[^a-zA-Z0-9_]/g, '').replace(/^\d+/g, '').toUpperCase();
      //item.getName().replace(/^\d+/g, '')
      //console.log( regex(/[0-9_]/g , item.getName() ) );
      let number = item.getName().match(/[0-9_]/g);
     // console.log(number);
      let newE;
      if(number!=null){
      newE =  new Entity(this, id,item.getName().replace(/[0-9_]/g, ++number) , x, y, false);
      }
      else{
      newE =  new Entity(this, id, item.getName()+"1" , x, y, false);
      }
      this._addItem(newE)
      //console.log(newE);
      //console.log(newE._id);
      //this.addAttribute()
      if(item.getAttributes()){
        //newE.addAttribute(item[a].getName()+"."+item[a].attrName, item[a].primaryKey, item[a].nullAttr);
        for (let a in item.getAttributes()){
          //let attr = item.getAttributes()[a].getName().split('.')[1];
          //console.log(attr);
          //console.log(newE.getName());
          this.addAttribute(newE.getName()+'.'+item.getAttributes()[a].getName().split('.')[1], newE._id, false, false, '', '', '', item.getAttributes()[a].getPrimaryKey(), item.getAttributes()[a].getNullAttr() );
        }
      }
        return newE;
    }
    return this._addItem(new Entity(this, id, name, x, y, false));
  }
  addRelationship(name, x, y) {
    const id = this._uid();
    const id_s=this._uid_s();
    name = name || `p${id_s}`;
    return this._addItem(new Relationship(this, id, name, x, y, 'unary', '', '', ''));
  }

  addRelationshipPi(name, x, y) {
    const id = this._uid();
    const id_s=this._uid_s();
    name = name || `p${id_s}`;
    return this._addItem(new Relationship(this, id, name, x, y, 'unaryPi', '', '', ''));
  }

  addRelationshipJoin(name, x, y) {
    //theta
    const id = this._uid();
    const id_s=this._uid_s();
    name = name || `p${id_s}`;
    return this._addItem(new Relationship(this, id, name, x, y, 'binary', '', '', ''));
  }
  addRelationshipNatJoin(name, x, y) {
    const id = this._uid();
    //const id_p=this._uid_p();
    name = '';
    return this._addItem(new Relationship(this, id, name, x, y, 'naturalJoin', '', '', ''));
  }

  addRelationshipSemiJoin(name, x, y) {
    const id = this._uid();
    const id_s=this._uid_s();
    name = name ||`p${id_s}`;
    return this._addItem(new Relationship(this, id, name, x, y, 'semi-join', '', '', ''));
  }
  addRelationshipASemiJoin(name, x, y) {
    const id = this._uid();
    const id_s=this._uid_s();
    name = name ||`p${id_s}`;
    return this._addItem(new Relationship(this, id, name, x, y, 'asemi-join', '', '', ''));
  }
  addRelationshipDifference(name, x, y) {
    const id = this._uid();
    //const id_j=this._uid_j();
    //name = name ||`${id_j}`;
    name ='';
    return this._addItem(new Relationship(this, id, name, x, y, 'difference', '', '', ''));
  }
  addRelationshipDivision(name, x, y) {
    const id = this._uid();
    //const id_d=this._uid_d();
    //name = name ||`${id_d}`;
    name='';
    return this._addItem(new Relationship(this, id, name, x, y, 'division', '', '', ''));
  }

  addRelationshipIntersection(name, x, y) {
    const id = this._uid();
    //const id_i=this._uid_i();
    //name = name ||`${id_i}`;
    name='';
    return this._addItem(new Relationship(this, id, name, x, y, 'intersection', '', '', ''));
  }
  addRelationshipUnion(name, x, y) {
    const id = this._uid();
    //const id_u=this._uid_d();
    //name = name ||`${id_u}`;
    name='';
    return this._addItem(new Relationship(this, id, name, x, y, 'union', '', '', ''));
  }

  addRelationshipResult(name, x, y) {
    const id = this._uid();
    this.uid_result++;
    name = ``;
    return this._addItem(new Relationship(this, id, name, x, y, 'result', '', '', ''));
  }
  addAttribute(name, parentId, identifier = false, externalIdentifier = false, cardinality = '1_1', x = null, y = null, primaryKey, nullAttr) {
    const id = this._uid();
    //const id_attr=this._uid_attr();
   // name = name || `Attribute${id_attr}`;
   /* if(x == null || y == null) {
      const attributePosition = idealAttributePosition(this, this.getItemById(parentId));
      x = attributePosition.x;
      y = attributePosition.y;
    }*/
    return this._addItem(new Attribute(this, id, name, identifier, externalIdentifier, cardinality, parentId, x, y, primaryKey, nullAttr));
  }
  addParticipation(entityId, tableId, relationshipId, cardinality = '1_1', externalIdentifier = false, role = '', comment = '') {
    if(entityId) {
      const { result, error } = this.getItemById(relationshipId).canAddParticipation(this.getItemById(entityId));
      if(!result)
        throw new Error(error);
        //console.log(this.getItemById(entityId));
        if(this.getItemById(entityId).__type=='Relationship'){
          this.getItemById(entityId).increaseParticipationCount();
          //console.log('increASE CHIAMATA')
          
        }
        this.getItemById(relationshipId).setTemporary(this.getItemById(entityId));
    }
    if(this.getItemById(relationshipId).getParticipations().length==0){
      cardinality='OUTER';
    }
    else 
      cardinality='INNER';
    return this._addItem(new Participation(this, this._uid(), entityId, tableId, relationshipId, cardinality, externalIdentifier, role, comment));
  }
  addGeneralization(parentEntityId, childEntityId) {
    const { result, error } = this.getItemById(childEntityId).canAddGeneralization(this.getItemById(parentEntityId));
    if(!result)
      throw new Error(error);
      
    let generalizationId = this.getItemWhere(i => i instanceof Generalization && i.getEntity().getId() == parentEntityId)?.getId();
    if(!generalizationId) {
      generalizationId = this._uid();
      this._addItem(new Generalization(this, generalizationId, 'p_e', parentEntityId));
    }

    return this._addItem(new GeneralizationChild(this, this._uid(), childEntityId, generalizationId));
  }
  addTable(name, x, y, generatedFromMultivaluedAttribute = false) {
    return this._addItem(new Table(this, this._uid(), name, x, y, generatedFromMultivaluedAttribute));
  }
  addColumn(name, tableId, tableIndex, identifier = false, nullable = false) {
    return this._addItem(new Column(this, this._uid(), name, columnTypes.INTEGER, identifier, false, nullable, tableId, tableIndex));
  }
  addForeignKey(columnAId, columnBId) {
    return this._addItem(new ForeignKey(this, this._uid(), columnAId, columnBId));
  }
  deleteItem(id) {
    const item = this.getItemById(id);
    if(item) {
      item.__beforeDelete?.();
      this._deleteItem(id);
      item.__afterDelete?.();
    }
  }
  restructureMultivalueAttribute(attributeId, unique = true) {
    restrMultivalued.restructureMultivalueAttribute(this, attributeId, unique);
  }
  splitCompositeAttribute(attributeId) {
    restrComposite.splitCompositeAttribute(this, attributeId);
  }
  mergeCompositeAttribute(attributeId) {
    restrComposite.mergeCompositeAttribute(this, attributeId);
  }
  /*collapseGeneralizationIntoParent(generalizationId) {
    restrGeneralization.collapseGeneralizationIntoParent(this, generalizationId);
  }
  collapseGeneralizationIntoChildren(generalizationId) {
    restrGeneralization.collapseGeneralizationIntoChildren(this, generalizationId);
  }*/
  substituteGeneralization(generalizationId) {
    restrGeneralization.substituteGeneralization(this, generalizationId);
  }
  translateEntity(entityId) {
    transEntity.translateEntity(this, entityId);
  }
  translateManyToManyRelationship(relationshipId) {
    transManyToMany.translateManyToManyRelationship(this, relationshipId);
  }
  translateOneToManyTypeARelationship(relationshipId) {
    transOneToMany.translateOneToManyTypeARelationship(this, relationshipId);
  }
  translateOneToManyTypeBRelationship(relationshipId, createTable = true) {
    transOneToMany.translateOneToManyTypeBRelationship(this, relationshipId, createTable);
  }
  translateOneToOneTypeARelationship(relationshipId) {
    transOneToOne.translateOneToOneTypeARelationship(this, relationshipId);
  }
  translateOneToOneTypeBRelationship(relationshipId, first = true) {
    transOneToOne.translateOneToOneTypeBRelationship(this, relationshipId, first);
  }
  translateOneToOneTypeCRelationship(relationshipId, createTable = true, first = true) {
    transOneToOne.translateOneToOneTypeCRelationship(this, relationshipId, createTable, first);
  }
  toERCode() {
    let code = '';

    const entities = this.getItemsWhere(i => i instanceof Entity);
    if(entities.length) {
      code += '/* Tables */\n';
      for(let entity of entities)
        code += entity.toERCode() + '\n';
      code += '\n';
    }

    const relationships = this.getItemsWhere(i => i instanceof Relationship);
    if(relationships.length) {
      code += '/* Operators */\n';
      for(let relationship of relationships)
        code += relationship.toERCode() + '\n';
      code += '\n';
    }

    const generalizations = this.getItemsWhere(i => i instanceof Generalization);
    if(generalizations.length)
      code += '/* Generalizations */\n';
    for(let generalization of generalizations)
      code += generalization.toERCode() + '\n';

    return code.trim();
  }
  toSQLCode() {
    let code = '';

    const tables = this.getItemsWhere(i => i instanceof Table);
    if(tables.length) {
      code += '/* Tables */\n';
      for(let table of tables)
        code += table.toSQLCode() + '\n';
      code += '\n';
    }

    return code.trim();
  }
}