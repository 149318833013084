import ForeignKey from './foreignKey';

export default class Column {
  constructor(model, id, name, type, identifier, unique, nullable, tableId, tableIndex) {
    this.__type = 'Column';
    this._model = () => model;
    this._id = id;
    this._name = name;
    this._type = type;
    this._identifier = identifier;
    this._unique = unique;
    this._nullable = nullable;
    this._tableId = tableId;
    this._tableIndex = tableIndex;
  }
  static fromObject(model, obj) {
    return new Column(model, obj._id, obj._name, obj._type, obj._identifier, obj._unique, obj._nullable, obj._tableId, obj._tableIndex);
  }
  getId() {
    return this._id;
  }
  getName() {
    return this._name;
  }
  getType() {
    return this._type;
  }
  isIdentifier() {
    return this._identifier;
  }
  isUnique() {
    return this._unique;
  }
  isNullable() {
    return this._nullable;
  }
  getTable() {
    return this._model().getItemById(this._tableId);
  }
  getTableIndex() {
    return this._tableIndex;
  }
  getForeignKey() {
    return this._model().getItemWhere(i => i instanceof ForeignKey && i.getReferencingColumn().getId() == this.getId());
  }
  isForeignKey() {
    return this.getForeignKey() != null;
  }
  getReferencingColumns() {
    return this._model().getItemsWhere(i => i instanceof ForeignKey && i.getReferencedColumn().getId() == this.getId()).map(fk => fk.getReferencingColumn());
  }
  getSupportedFunctionalities() {
    return {
      unique: true,
      dataType: true,
      reordering: true,
      renaming: true
    };
  }
  getAllowedFunctionalities() {
    let unique = { ok: true };
    if(this.isIdentifier())
      unique = { ok: false, error: 'A primary key is unique by default.' };

    let reorderingMoveUp = { ok: true };
    const prevColumn = this.getTable().getColumnByIndex(this.getTableIndex() - 1);
    if(prevColumn == null || (!this.isIdentifier() && prevColumn.isIdentifier()))
      reorderingMoveUp = { ok: false };

    let reorderingMoveDown = { ok: true };
    const nextColumn = this.getTable().getColumnByIndex(this.getTableIndex() + 1);
    if(nextColumn == null || (this.isIdentifier() && !nextColumn.isIdentifier()))
      reorderingMoveDown = { ok: false };

    return {
      unique,
      reorderingMoveUp,
      reorderingMoveDown
    };
  }
  setName(name) {
    name = name.replace(/\s/g, '_').replace(/[^a-zA-Z0-9_]/g, '').replace(/^\d+/g, '');
    if(name.length)
      this._name = name;
    return name;
  }
  setType(type) {
    this._type = type;
    for(let column of this.getReferencingColumns())
      column.setType(type);
  }
  setUnique(unique) {
    this._unique = unique;
  }
  setTableIndex(index) {
    this._tableIndex = index;
  }
  toSQLCode() {
    let code = this.getName();
    let modifiers = [this.getType().toUpperCase()];

    if(!this.isNullable())
      modifiers.push('NOT NULL');
    if(this.isUnique())
      modifiers.push('UNIQUE');

    code += ' ' + modifiers.join(' ');

    return code;
  }
}