<template>
  <div class="code-editor-container editor">
    <div class="code-container">
      <pre class="language-sql"><code class="language-sql" v-html="sqlCode"></code></pre>
    </div>
  </div>
</template>

<script>
import { mainStore } from '../store/main';

export default {
  name: 'SQLCode',
  setup() {
    const store = mainStore.schemaDesign;
    const sqlCode = store.$highlightedCode();

    return {
      sqlCode
    };
  }
};
</script>

<style scoped>
.code-container {
  overflow: auto;
}
</style>