<template>
  <CodeModelLayout codeHeaderTitle="SQL" modelHeaderTitle="Schema">
    <template v-slot:code>
      <SQLCode />
    </template>
    <template v-slot:model>
      <SchemaDesignEditor />
    </template>
  </CodeModelLayout>
</template>

<script>
import CodeModelLayout from './CodeModelLayout';
import SchemaDesignEditor from './SchemaDesignEditor';
import SQLCode from './SQLCode';

export default {
  name: 'SchemaDesign',
  components: {
    CodeModelLayout,
    SchemaDesignEditor,
    SQLCode
  }
};
</script>