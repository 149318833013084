export const translateManyToManyRelationship = function(model, relationshipId) {
  let columnIndex = 0;
  const relationship = model.getItemById(relationshipId);
  const table = model.addTable(relationship.getName(), relationship.getX(), relationship.getY());
  for(let participation of relationship.getParticipations()) {
    const participatingTable = participation.getTable();
    if(participatingTable.wasGeneratedFromMultivaluedAttribute()) {
      const column = participatingTable.getColumns()[0];
      const columnName = `${column.getTable().getName()}_${column.getName()}${participation.getRole() ? '_' + participation.getRole() : ''}`;
      model.addColumn(columnName, table.getId(), columnIndex++, true, false);
    } else {
      for(let column of participatingTable.getColumns().filter(c => c.isIdentifier())) {
        const columnName = `${column.getTable().getName()}_${column.getName()}${participation.getRole() ? '_' + participation.getRole() : ''}`;
        const genColumn = model.addColumn(columnName, table.getId(), columnIndex++, true, false);
        model.addForeignKey(genColumn.getId(), column.getId());
      }
    }
  }
  for(let attribute of relationship.getAttributes())
    model.addColumn(attribute.getName(), table.getId(), columnIndex++, false, attribute.getMinCardinality() == '0');
  for(let redundantTable of relationship.getParticipations().map(p => p.getTable()).filter(t => t.wasGeneratedFromMultivaluedAttribute()))
    model.deleteItem(redundantTable.getId());
  model.deleteItem(relationship.getId());
};