<template>
  <div>
  <div class="align"><button class="close-btn" @click="handleBack()">X</button></div>
  <div class="feedback">
      <div v-if="!feedbackSent">
        <h2>We're eager to hear your thoughts&nbsp;<br />about our product.</h2>
        <p>Our mission is to create the best educational tool for conceptual data modeling. And we definitely have fun making it chock-full of features and as user-friendly as computerly possible.</p>
        <p>But at the end of the day, we develop this tool with you in mind. That is why we would love to know how much you enjoy using it.</p>
        <div class="feedback-stars">
          <fieldset class="rating">
            <input type="radio" id="star5" name="rating" value="5" @click="vote(5)" /><label class = "full" for="star5" title="Awesome - 5 stars"></label>
            <input type="radio" id="star4half" name="rating" value="4.5" @click="vote(4.5)" /><label class="half" for="star4half" title="Pretty good - 4.5 stars"></label>
            <input type="radio" id="star4" name="rating" value="4" @click="vote(4)" /><label class = "full" for="star4" title="Pretty good - 4 stars"></label>
            <input type="radio" id="star3half" name="rating" value="3.5" @click="vote(3.5)" /><label class="half" for="star3half" title="Meh - 3.5 stars"></label>
            <input type="radio" id="star3" name="rating" value="3" @click="vote(3)" /><label class = "full" for="star3" title="Meh - 3 stars"></label>
            <input type="radio" id="star2half" name="rating" value="2.5" @click="vote(2.5)" /><label class="half" for="star2half" title="Kinda bad - 2.5 stars"></label>
            <input type="radio" id="star2" name="rating" value="2" @click="vote(2)" /><label class = "full" for="star2" title="Kinda bad - 2 stars"></label>
            <input type="radio" id="star1half" name="rating" value="1.5" @click="vote(1.5)" /><label class="half" for="star1half" title="Meh - 1.5 stars"></label>
            <input type="radio" id="star1" name="rating" value="1" @click="vote(1)" /><label class = "full" for="star1" title="Sucks big time - 1 star"></label>
            <input type="radio" id="starhalf" name="rating" value="0.5" @click="vote(0.5)" /><label class="half" for="starhalf" title="Sucks big time - 0.5 stars"></label>
          </fieldset>
        </div>
        <div class="send">
          <button class="my-btn" v-bind:disabled="!voted" @click="sendFeedback()">send</button>
        </div>
      </div>
      <div v-if="feedbackSent">
        <h2>{{ score }}/<span style="font-weight: normal">5</span></h2>
        <h3>Thanks for your feedback,<br />we truly appreciate it.</h3>
      </div>
  </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import analytics from '../services/analytics';
import analyticsEvents from '../services/analytics/events';
import { mainStore } from '../store/main';

export default {
  name: 'Feedback',
  methods : {
    handleBack(){
      mainStore.closeModal();
    }
  },
  setup() {
    const score = ref(null);
    const voted = ref(false);
    const feedbackSent = ref(false);

    function vote(stars) {
      score.value = stars;
      voted.value = true;
    }
    function sendFeedback() {
      feedbackSent.value = true;
      analytics.event(analyticsEvents.APP_FEEDBACK, { score: score.value });
    }
    return {
      score,
      voted,
      feedbackSent,
      vote,
      sendFeedback
    };
  }
};
</script>

<style scoped>
@import url(//netdna.bootstrapcdn.com/font-awesome/3.2.1/css/font-awesome.css);
.my-btn{
  padding: 4px 10px;
  background: transparent;
  border: 2px solid #191716;
  border-radius: 10px;
  font-weight: 600;
  font-family: 'Commissioner', sans-serif;
  text-transform: uppercase;
  outline: none;
}
.align{
  text-align: right;
  margin-bottom: 0px;
  margin-top: 7px;
}
.close-btn{
  background-color: transparent !important;
  font-size: 18px;
  font-weight: 800;
  padding: 4px 16px 0px 0px;
}
h2 {
  margin-bottom: 1.3em;
  margin-top: 0px;
}
h3 {
  margin-bottom: 0;
}
.feedback {
  text-align: center;
  padding: 0px 40px 40px 40px;
}
.feedback-stars {
  margin: 2em 0 0 0;
}
.send {
  margin: 2em 0 0 0;
}
fieldset {
  width: 190px;
}
fieldset, label {
  margin: auto;
  padding: 0;
}
.rating { 
  border: none;
  font-size: 1.5em;
}
.rating > input {
  display: none;
} 
.rating > label:before { 
  margin: 5px;
  font-size: 1.25em;
  font-family: FontAwesome;
  display: inline-block;
  content: "\f005";
}
.rating > .half:before { 
  content: "\f089";
  position: absolute;
}
.rating > label { 
  color: #ddd; 
  float: right; 
}
.rating > input:checked ~ label, .rating:not(:checked) > label:hover, .rating:not(:checked) > label:hover ~ label {
  color: #FFD700;
}
.rating > input:checked + label:hover, .rating > input:checked ~ label:hover, .rating > label:hover ~ input:checked ~ label, .rating > input:checked ~ label:hover ~ label {
  color: #FFED85;
}
@media all and (max-width: 720px) {
  br {
    display: none;
  }
  .feedback {
    font-size: 0.85em;
    padding: 10px 20px 40px 20px;
  }
}
</style>