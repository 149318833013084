<template>
  <div class="code-editor-container editor">
    <div class="code-container">
      <textarea spellcheck="false" @scroll="onscroll($event)" v-model="erCode"></textarea>
      <pre ref="codeBlock" class="language-er"><code class="language-er" v-html="html"></code></pre>
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue';

import { mainStore } from '../store/main';

export default {
  name: 'ERCodeEditor',
  setup() {
    const codeBlock = ref(null);

    const store = mainStore.erDesign;
    const erCode = computed({
      get: () => store.getCode(),
      set: val => store.setCode(val)
    });
    const html = store.$highlightedCode();

    function onscroll(event) {
      codeBlock.value.style.top = `-${event.target.scrollTop}px`;
    }

    return {
      codeBlock,
      erCode,
      html,
      onscroll
    };
  }
};
</script>

<style scoped>
textarea {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  padding: 0 8px 0 0;
  z-index: 1;
  resize: none;
  color: transparent;
  box-sizing: border-box;
  caret-color: rgb(0, 0, 0);
  font: inherit;
  font-feature-settings: inherit;
  font-optical-sizing: inherit;
  background: transparent;
  border: none;
  outline: none;
}
</style>