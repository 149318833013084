import { events } from './events';
import { bound, getTouch } from '../utils/core';
import config from '../../config';

const handlers = {
  move(x, y, ctx) {
    if(events.onMove && events.onMove(x, y, ctx) === false)
      return;
    ctx.two.update();
  },
  click(x, y, target, ctx) {
    if(events.onClick && events.onClick(x, y, target, ctx) === false)
      return;
    if(ctx.callbacks && ctx.callbacks.selectItem) {
      for(let node = target; node.tagName != 'svg'; node = node.parentElement) {
        const id = ctx.diagram.svgIds[node.id];
        if(id) {
          ctx.callbacks.selectItem(id);
          return;
        }
      }
      ctx.callbacks.selectItem(null);
    }
  },

  keydown(key, ctrl, ctx, alt,e) {
    if(ctrl && key == 78)
      e.preventDefault();
    if(events.onKey && events.onKey(key, ctrl, ctx, alt, e) === false)
      return;
  },
  pan(dx, dy, ctx) {
    if(events.onPan && events.onPan(dx, dy, ctx) === false)
      return;
    const modelScene = ctx.diagram.scene.$model;
    modelScene.translation.add(dx, dy);
    [ctx.transform.offsetX, ctx.transform.offsetY] = [modelScene.translation.x, modelScene.translation.y];
    ctx.two.update();
  },
  panend(ctx) {
    if(events.onPanEnd && events.onPanEnd(ctx) === false)
      return;
  },
  zoom(f, centerX, centerY, ctx) {
    if(events.onZoom && events.onZoom(f, centerX, centerY, ctx) === false)
      return;
    const modelScene = ctx.diagram.scene.$model, overlaysScene = ctx.diagram.scene.$overlays;
    const oldScale = modelScene.scale;
    f = bound(oldScale * f, config.erDesign.minScale, config.erDesign.maxScale) / oldScale;
    const newScale = oldScale * f;
    modelScene.scale = overlaysScene.scale = newScale;
    modelScene.translation.sub(
      (centerX - modelScene.translation.x) * (f - 1),
      (centerY - modelScene.translation.y) * (f - 1)
    );
    [ctx.transform.scale, ctx.transform.offsetX, ctx.transform.offsetY] = [modelScene.scale, modelScene.translation.x, modelScene.translation.y];
    ctx.two.update();
  }
};

const listeners = {
  mousedown(_, ctx) {
    ctx.mousePressed = true;
  },
  mouseup(_, ctx) {
    const panning = ctx.panning;
    ctx.mousePressed = false;
    ctx.panning = false;
    if(panning)
      handlers.panend(ctx);
  },
  click(e, ctx) {
    if(!ctx.allowClick)
      return;
    const x = bound(e.pageX - ctx.base.x, 0, ctx.canvas.width);
    const y = bound(e.pageY - ctx.base.y, 0, ctx.canvas.height);
    handlers.click(x, y, e.target, ctx);
  },
  mousemove(e, ctx) {
    ctx.allowClick = !ctx.mousePressed;
    const x = bound(e.pageX - ctx.base.x, 0, ctx.canvas.width);
    const y = bound(e.pageY - ctx.base.y, 0, ctx.canvas.height);
    if(ctx.mousePressed) {
      ctx.panning = true;
      handlers.pan(x - ctx.mouse.x, y - ctx.mouse.y, ctx);
    } else
      handlers.move(x, y, ctx);
    ctx.mouse.x = x;
    ctx.mouse.y = y;
  },
  mouseleave(_, ctx) {
    const panning = ctx.panning;
    ctx.mousePressed = false;
    ctx.panning = false;
    ctx.allowClick = false;
    if(panning)
      handlers.panend(ctx);
  },
  keydown(e, ctx) {
    handlers.keydown(e.which, e.ctrlKey, ctx, e.shiftKey, e);
  },
  contextmenu(e) {
    e.preventDefault();
    return false;
  },
  _wheel(e, ctx) {
    if(e.type == 'wheel')
      ctx.supportsWheel = true;
    else if(ctx.supportsWheel)
      return;
    handlers.zoom(-(((e.deltaY || -e.wheelDelta || e.detail) >> 10) || 1) * 0.2 + 1, ctx.mouse.x, ctx.mouse.y, ctx);
  },
  wheel(e, ctx) {
    e.preventDefault();
    this._wheel(e, ctx);
    return false;
  },
  mousewheel(e, ctx) {
    e.preventDefault();
    this._wheel(e, ctx);
    return false;
  },
  DOMMouseScroll(e, ctx) {
    e.preventDefault();
    this._wheel(e, ctx);
    return false;
  },
  touchstart(e, ctx) {
    ctx.fingers.oneX = bound(getTouch(e.touches, 0).pageX - ctx.base.x, 0, ctx.canvas.width);
    ctx.fingers.oneY = bound(getTouch(e.touches, 0).pageY - ctx.base.y, 0, ctx.canvas.height);
    if(e.touches.length == 2) {
      ctx.fingers.twoX = bound(getTouch(e.touches, 1).pageX - ctx.base.x, 0, ctx.canvas.width);
      ctx.fingers.twoY = bound(getTouch(e.touches, 1).pageY - ctx.base.y, 0, ctx.canvas.height);
    }
  },
  touchmove(e, ctx) {
    const oneX = bound(getTouch(e.touches, 0).pageX - ctx.base.x, 0, ctx.canvas.width);
    const oneY = bound(getTouch(e.touches, 0).pageY - ctx.base.y, 0, ctx.canvas.height);
    if(e.touches.length == 1) {
      ctx.panning = true;
      handlers.pan(oneX - ctx.fingers.oneX, oneY - ctx.fingers.oneY, ctx);
    } else if(e.touches.length == 2) {
      ctx.panning = true;
      if(ctx.diagram.selectedItem && ctx.callbacks.selectItem)
        ctx.callbacks.selectItem(null);
      const twoX = bound(getTouch(e.touches, 1).pageX - ctx.base.x, 0, ctx.canvas.width);
      const twoY = bound(getTouch(e.touches, 1).pageY - ctx.base.y, 0, ctx.canvas.height);
      const zoom = (1 + Math.hypot(oneX - twoX, oneY - twoY) / Math.hypot(ctx.fingers.oneX - ctx.fingers.twoX, ctx.fingers.oneY - ctx.fingers.twoY)) / 2;
      handlers.zoom(zoom, (ctx.fingers.oneX + ctx.fingers.twoX) / 2, (ctx.fingers.oneY + ctx.fingers.twoY) / 2, ctx);
      handlers.pan((oneX - ctx.fingers.oneX + twoX - ctx.fingers.twoX) / 2, (oneY - ctx.fingers.oneY + twoY - ctx.fingers.twoY) / 2, ctx);
      ctx.fingers.twoX = twoX;
      ctx.fingers.twoY = twoY;
    }
    ctx.fingers.oneX = oneX;
    ctx.fingers.oneY = oneY;
  },
  touchend(_, ctx) {
    const panning = ctx.panning;
    ctx.panning = false;
    if(panning)
      handlers.panend(ctx);
  },
  touchcancel(_, ctx) {
    const panning = ctx.panning;
    ctx.panning = false;
    if(panning)
      handlers.panend(ctx);
  }
};

export const handle = (e, ctx) => {
  if(listeners[e.type])
    listeners[e.type](e, ctx);
};