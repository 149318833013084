<template>
  <div class="modal-super flex" v-on:click="closeModal()">
    <div class="modal-container" v-on:click.stop>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { mainStore } from '../store/main';

export default {
  name: 'Modal',
  setup() {
    function closeModal() {
      mainStore.closeModal();
    }

    return {
      closeModal
    };
  }
};
</script>

<style scoped>
.modal-super {
  width: 100%;
  height: 100%;
  justify-content: center;
}
.modal-container {
  width: 600px;
  margin: auto;
  max-width: 80%;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
}
</style>