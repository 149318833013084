import colors from './colors';
import DiagramGroup from './diagramGroup';
import DiagramText from './diagramText';

const drawEntityOverlay = () => {
  let _text = new DiagramText('Table');
  _text.family = 'Commissioner';
  _text.fill = colors.BLACK;
  _text.size = 21;
  _text.weight = 400;

  return _text;
};
const drawUnaryOverlay = () => {
  let _text = new DiagramText('σ');
  _text.family = 'Commissioner';
  _text.fill = colors.BLACK;
  _text.size = 28;
  _text.weight = 400;

  return _text;
};

const drawUnaryPiOverlay = () => {
  let _text = new DiagramText('π');
  _text.family = 'Commissioner';
  _text.fill = colors.BLACK;
  _text.size = 28;
  _text.weight = 400;

  return _text;
}; 

const drawBinaryInput = () => {
  let _binText = new DiagramText('⋈');
  _binText.family = 'Commissioner';
  _binText.fill = colors.BLACK;
  _binText.size = 28;
  _binText.weight = 400;

  return _binText;
};
const drawSemijoin = () => {
  let _binText = new DiagramText('⋉');
  _binText.family = 'Commissioner';
  _binText.fill = colors.BLACK;
  _binText.size = 28;
  _binText.weight = 400;

  return _binText;
};
const drawDifference = () => {
  let _binText = new DiagramText('—');
  _binText.family = 'Commissioner';
  _binText.fill = colors.BLACK;
  _binText.size = 28;
  _binText.weight = 400;

  return _binText;
};
const drawDivision = () => {
  let _binText = new DiagramText('/');
  _binText.family = 'Commissioner';
  _binText.fill = colors.BLACK;
  _binText.size = 28;
  _binText.weight = 400;

  return _binText;
};
const drawUnion = () => {
  let _binText = new DiagramText('∪');
  _binText.family = 'Commissioner';
  _binText.fill = colors.BLACK;
  _binText.size = 28;
  _binText.weight = 400;

  return _binText;
};
const drawIntersection = () => {
  let _binText = new DiagramText('ᑎ');
  _binText.family = 'Commissioner';
  _binText.fill = colors.BLACK;
  _binText.size = 28;
  _binText.weight = 400;

  return _binText;
};
const drawResult = () => {
  let _binText = new DiagramText('R');
  _binText.family = 'Commissioner';
  _binText.fill = colors.BLACK;
  _binText.size = 28;
  _binText.weight = 400;

  return _binText;
};

class OverlayGroup extends DiagramGroup {
  constructor(context) {
    super(null, context);
    this.erEntity = drawEntityOverlay();
    this.raSigma = drawUnaryOverlay();
    this.raPi =drawUnaryPiOverlay();
    this.raJoin = drawBinaryInput();
    this.raSemiJoin = drawSemijoin();
    this.raDifference = drawDifference();
    this.raDivision = drawDivision();
    this.raUnion = drawUnion();
    this.raIntersection = drawIntersection();
    this.raResut = drawResult();

    this.add(this.erEntity, this.raSigma, this.raPi, this.raJoin, this.raSemiJoin, this.raDifferencem, this.raDivision, this.raUnion, this.raIntersection, this.raResut);
    }
  showOverlay(tool) {
    this.erEntity.opacity = tool == 'entity' ? 0.5 : 0;
    this.raSigma.opacity = tool == 'unaryInput' ? 0.5 : 0;
    this.raPi.opacity = tool == 'unaryInputPi' ? 0.5 : 0;
    if(tool == 'binaryInput' || tool == 'natural-join')
      this.raJoin.opacity = 0.5;
    else 
      this.raJoin.opacity = 0;
    if(tool == 'semi-join' || tool == 'asemi-join')
      this.raSemiJoin.opacity = 0.5;
    else 
      this.raSemiJoin.opacity = 0;
    this.raDifference.opacity = tool == 'difference' ? 0.5 : 0;
    this.raDivision.opacity = tool == 'division' ? 0.5 : 0;
    this.raUnion.opacity = tool == 'union' ? 0.5 : 0;
    this.raIntersection.opacity = tool == 'intersection' ? 0.5 : 0;
    this.raResut.opacity = tool == 'result' ? 0.5 : 0;

  }
}

export const drawOverlays = context => {
  const scene = context.diagram.scene;
  let _overlays = scene.$overlays;

  if(!_overlays) {
    _overlays = new OverlayGroup(context);
    scene.$overlays = _overlays;

    context.two.makeGroup(_overlays);
  }
  
  scene.$overlays.scale = context.transform.scale;

  _overlays.setPosition(-10000, -10000);
  _overlays.showOverlay(context.diagram.selectedTool);

  return _overlays;
};