import { computed } from 'vue';

import { Store } from './store';
import Model from '../lib/model';
import Attribute from '../lib/attribute';
import Generalization from '../lib/generalization';
import GeneralizationChild from '../lib/generalizationChild';
import analytics from '../services/analytics';
import analyticsEvents from '../services/analytics/events';

export default class ERRestructuringStore extends Store {
  constructor(main) {
    super();
    this.mainStore = main;
  }
  __replace(state) {
    this.state.edited = state.edited;
    this.state.sceneResetToken = new Date().getTime();

    this.state.selectedItemId = null;
    this.state.oldRenamingValue = '';
    this.state.renamingValue = '';
    this.state.showRenamingInput = false;
    this.state.showRenamingRoleInput = false;
    this.state.showMultivalueAttributeMenu = false;
    this.state.showCompositeAttributeMenu = false;
    this.state.showGeneralizationMenu = false;
    
    this.state.model = Model.fromObject(state.model || {});
  }
  data() {
    return {
      model: new Model(),
      edited: false,
      sceneResetToken: null,
      selectedItemId: null,
      oldRenamingValue: '',
      renamingValue: '',
      showRenamingInput: false,
      showRenamingRoleInput: false,
      showMultivalueAttributeMenu: false,
      showCompositeAttributeMenu: false,
      showGeneralizationMenu: false
    };
  }
  _resetMenus() {
    let updated = false;
    if((this.state.showRenamingInput || this.state.showRenamingRoleInput) && (this.state.oldRenamingValue != this.state.renamingValue))
      updated = true;

    this.state.showMultivalueAttributeMenu = false;
    this.state.showCompositeAttributeMenu = false;
    this.state.showGeneralizationMenu = false;

    if(updated) {
      this._itemsUpdated();
      const oldName = this.state.oldRenamingValue, newName = this.state.renamingValue;
      if(this.state.showRenamingInput) {
        const itemType = this.state.model.getItemById(this.state.selectedItemId).constructor.name;
        analytics.event(analyticsEvents.RESTR_ITEM_RENAME, { itemType, oldName, newName });
      } else if(this.state.showRenamingRoleInput) {
        analytics.event(analyticsEvents.RESTR_PARTICIPATION_ROLE_RENAME, { oldName, newName });
      }
    }
    
    this.state.showRenamingInput = false;
    this.state.showRenamingRoleInput = false;
  }
  _itemsUpdated(pushState = true) {
    this.state.edited = true;
    if(pushState)
      this.mainStore.pushState();
  }
  $sceneResetToken() {
    return computed(() => this.state.sceneResetToken);
  }
  $selectedItem() {
    return computed(() => this.getSelectedItem());
  }
  $renamingInput() {
    return computed(() => this.state.showRenamingInput);
  }
  $renamingRoleInput() {
    return computed(() => this.state.showRenamingRoleInput);
  }
  $multivalueAttributeMenu() {
    return computed(() => this.state.showMultivalueAttributeMenu);
  }
  $compositeAttributeMenu() {
    return computed(() => this.state.showCompositeAttributeMenu);
  }
  $generalizationMenu() {
    return computed(() => this.state.showGeneralizationMenu);
  }
  $selectedItemWarnings() {
    return computed(() => {
      const item = this.getSelectedItem();
      if(item && item.getWarnings)
        return item.getWarnings().filter(w => !w.scope || w.scope == 'erRestructuring');
      return null;
    });
  }
  $selectedItemErrors() {
    return computed(() => {
      const item = this.getSelectedItem();
      if(item && item.getErrors)
        return item.getErrors().filter(e => !e.scope || e.scope == 'erRestructuring');
      return null;
    });
  }
  $modelContainsGeneralizations() {
    return computed(() => this.state.model.getItems().some(i => i instanceof Generalization));
  }
  $canTranslate() {
    return computed(() => !this.state.model.needsRestructuring());
  }
  getSelectedItem() {
    return this.state.selectedItemId ? this.state.model.getItemById(this.state.selectedItemId) : null;
  }
  getRenamingValue() {
    return this.state.renamingValue;
  }
  selectItem(id) {
    this._resetMenus();
    this.state.selectedItemId = id;

    if(id) {
      const item = this.getSelectedItem();
      if(item instanceof GeneralizationChild)
        this.state.selectedItemId = item.getGeneralization().getId();
    }
  }
  moveItem(dx, dy) {
    this._resetMenus();
    const item = this.getSelectedItem();
    if(item.getSupportedFunctionalities().moving)
      item.move(dx, dy);
  }
  finishedMovingItem() {
    const item = this.getSelectedItem();
    if(item.getSupportedFunctionalities().moving)
      this._itemsUpdated();
  }
  renameItem(name) {
    const item = this.getSelectedItem();
    if(item.getSupportedFunctionalities().renaming)
      this.state.renamingValue = item.setName(name);
    this._itemsUpdated(false);
  }
  setRole(role) {
    const item = this.getSelectedItem();
    if(item.getSupportedFunctionalities().role)
      this.state.renamingValue = item.setRole(role);
    this._itemsUpdated(false);
  }
  restructureItem() {
    const item = this.getSelectedItem();
    if(item instanceof Attribute && item.getCardinality().endsWith('_N'))
      this.toggleMenu('multivalueAttribute');
    else if(item instanceof Attribute && item.hasSubattributes())
      this.toggleMenu('compositeAttribute');
    else if(item instanceof Generalization)
      this.toggleMenu('generalization');
  }
  restructureMultivalueAttribute(strategy) {
    const attributeId = this.state.selectedItemId;
    if(strategy == 'unique') {
      this.state.model.restructureMultivalueAttribute(attributeId, true);
      analytics.event(analyticsEvents.RESTR_MULTIVALUED_UNIQUE);
    } else if(strategy == 'shared') {
      this.state.model.restructureMultivalueAttribute(attributeId, false);
      analytics.event(analyticsEvents.RESTR_MULTIVALUED_SHARED);
    }
    this.state.selectedItemId = null;
    this._itemsUpdated();
  }
  restructureCompositeAttribute(strategy) {
    const attributeId = this.state.selectedItemId;
    if(strategy == 'split') {
      this.state.model.splitCompositeAttribute(attributeId);
      analytics.event(analyticsEvents.RESTR_COMPOSITE_SPLIT);
    } else if(strategy == 'merge') {
      this.state.model.mergeCompositeAttribute(attributeId);
      analytics.event(analyticsEvents.RESTR_COMPOSITE_MERGE);
    }
    this.state.selectedItemId = null;
    this._itemsUpdated();
  }
  restructureGeneralization(strategy) {
    const generalizationId = this.state.selectedItemId;
    if(strategy == 'collapse_into_parent') {
      this.state.model.collapseGeneralizationIntoParent(generalizationId);
      analytics.event(analyticsEvents.RESTR_GENERALIZATION_COLLAPSE_INTO_PARENT);
    } else if(strategy == 'collapse_into_children') {
      this.state.model.collapseGeneralizationIntoChildren(generalizationId);
      analytics.event(analyticsEvents.RESTR_GENERALIZATION_COLLAPSE_INTO_CHILDREN);
    } else if(strategy == 'substitute') {
      this.state.model.substituteGeneralization(generalizationId);
      analytics.event(analyticsEvents.RESTR_GENERALIZATION_SUBSTITUTE);
    }
    this.state.selectedItemId = null;
    this._itemsUpdated();
  }
  toggleMenu(menu) {
    if(menu == 'renaming') {
      const showRenaming = this.state.showRenamingInput;
      this._resetMenus();
      this.state.showRenamingInput = !showRenaming;
      this.state.oldRenamingValue = this.getSelectedItem().getName();
      this.state.renamingValue = this.getSelectedItem().getName();
    } else if(menu == 'renamingRole') {
      const showRenamingRole = this.state.showRenamingRoleInput;
      this._resetMenus();
      this.state.showRenamingRoleInput = !showRenamingRole;
      this.state.oldRenamingValue = this.getSelectedItem().getRole();
      this.state.renamingValue = this.getSelectedItem().getRole();
    } else if(menu == 'multivalueAttribute') {
      const showMultivalueAttribute = this.state.showMultivalueAttributeMenu;
      this._resetMenus();
      this.state.showMultivalueAttributeMenu = !showMultivalueAttribute;
    } else if(menu == 'compositeAttribute') {
      const showCompositeAttribute = this.state.showCompositeAttributeMenu;
      this._resetMenus();
      this.state.showCompositeAttributeMenu = !showCompositeAttribute;
    } else if(menu == 'generalization') {
      const showGeneralization = this.state.showGeneralizationMenu;
      this._resetMenus();
      this.state.showGeneralizationMenu = !showGeneralization;
    } else
      this._resetMenus();
  }
}