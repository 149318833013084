<template>
<table class="info-schema-temp">
    <thead>
      <tr>
         <th>Attributes</th>
      </tr>     
    </thead>
    <div v-if="isSetOperator()">
      <div  v-if="erDesignStore.getSelectedItem().getGenericET().isRelationship()">
        <tbody v-if="erDesignStore.getSelectedItem().getGenericET().getTempAttrs()">
        <tr v-for="i in erDesignStore.getSelectedItem().getGenericET().getTempAttrs()" :key="i">
          <td>{{i}}</td>
        </tr>
        </tbody>
      </div>
      <tbody v-else>
        <tr>
          <td> - </td>
        </tr>
      </tbody>
    </div>
    <div v-else>
      <div  v-if="erDesignStore.getSelectedItem().getGenericET().isRelationship()">
        <tbody v-if="erDesignStore.getSelectedItem().getGenericET().getTempAttrs()">
          <tr v-for="i in erDesignStore.getSelectedItem().getGenericET().getTempAttrsName()" :key="i">
            <td>{{i}}</td>
          </tr>
        </tbody>
      </div>
      <tbody v-else>
        <tr>
          <td> - </td>
        </tr>
      </tbody>
    </div>
  </table>  
</template>

<script>
import { mainStore } from '../store/main';
import Model from '../lib/model'
import Relationship from '../lib/relationship'
//import Attribute from '../lib/attribute'
export default {
   name: 'RAInfoTemp',
   components: {
    //
  },
  data() {
  return {
      model: new Model(),
      item:  {attrName: '', primaryKey: false, nullAttr: false},
      items: []
      }      
    },
  methods: {
    handleBody(genericET){
      if(genericET instanceof Relationship && genericET.getTemporary()!=''){
        return(genericET.getTemporary());
      }
    },
    checkPrimaryKey(){
      if(this.item.primaryKey!='')
        return this.item.primaryKey;
      else
       return false;
    },
    handleAttrName(e){
      this.item.attrName=e.target.value;
    },
    handlePrimaryKey(e){
      this.item.primaryKey=e.target.checked;
      //e.taget ? disableNull=e.target.checked : false;
    },
    handleNull(e){
      this.item.nullAttr=e.target.checked;
    },
    handleAdd(e){   
      e.preventDefault();   
      if(this.item.primaryKey=='')
        this.item.primaryKey=false;
      if(this.item.nullAttr=='')
         this.item.nullAttr=false;
      if(this.item.attrName=='')
        return;
      //this.item.id=count;
      //new ItemAttr(count, this.item.attrName, this.item.primaryKey, this.item.handleNull);
      //this.items.push(item);
      //this.items.pop(item);
      //this.itemsUpdated();
      //this.item.attrName='';
      //this.item.nullAttr='';
      //this.item.primaryKey='';
      //this.itemsUpdated();        
      e.preventDefault();
      this.erDesignStore.addAttribute(this.item.attrName, this.item.primaryKey, this.item.nullAttr);
      e.preventDefault();
      document.getElementById("userText").value="";
      this.item.attrName="";     
    },
    isSetOperator(){
      if(this.erDesignStore.getSelectedItem().getGenericET().isRelationship()){
        if(this.erDesignStore.getSelectedItem().getGenericET().getTypeOfInPut()=='difference' || this.erDesignStore.getSelectedItem().getGenericET().getTypeOfInPut()=='union' || this.erDesignStore.getSelectedItem().getGenericET().getTypeOfInPut()=='intersection'){
          return true;
        }
      }
      return false;
    }
   },
    setup() {
      const erDesignStore = mainStore.erDesign;
      // let item = {id: 0, attrName: '', primaryKey: false, nullAttr: false};
      //let disableNull = false;
      /*function handleAttrName(e){
          item.attrName=e.target.value;
        }
      function handlePrimaryKey(e){
          item.primaryKey=e.target.checked;
          e.taget ? disableNull=e.target.checked : false;
        }
      function handleNull(e){
          item.nullAttr=e.target.checked;
        }
      function handleAdd(){      
        if(item.primaryKey=='')
          item.primaryKey=false;
        if(item.nullAttr=='')
          item.nullAttr=false;
        if(item.attrName=='')
          return;
        //this.item.id=count;
        //new ItemAttr(count, this.item.attrName, this.item.primaryKey, this.item.handleNull);
        //this.items.push(item);
        //this.items.pop(item);
        //this.itemsUpdated();
        //console.log(this.items);
        //this.item.attrName='';
        //this.item.nullAttr='';
        //this.item.primaryKey='';
        //this.itemsUpdated();
        erDesignStore.addAttribute(item.attrName, item.primaryKey, item.nullAttr);
        }*/
      function handleDelete(id){
        erDesignStore.deleteAttribute(id);   
      }
      
    return {
      erDesignStore,
      handleDelete,
    }
  },   
};
</script>

<style>
.info-schema-temp {
  position: absolute;
  left: 77px;
  top: 152px;  
  background-color: #f0f0f0; 
  padding: 5px; 
  border-radius: 4px; 
  border-spacing: 0 5px;   
}
th{
  font-family: 'Commissioner', sans-serif;
  font-size: 14px;
  font-weight: normal;
}

td, th{
  padding-inline: 6px;
  position: relative;
  right: 50 px;
  text-anchor: middle;
}

tr{
  font-family: 'Commissioner', sans-serif;
  font-size: 13px;    
}
#userText{
  width:130px;
  height: 13px;
}
button{
  font-family: 'Commissioner', sans-serif;
  background: #e6af2e;
  background-color: #e6af2e;
  border: #e6af2e;
  border-radius: 6px;
  font-size: 10px;
  font-weight: 400; 
}
.button-add{
  position: relative;
  left: 240px; 
}
.separator{
  height: 1px;
  margin: 6px 0;
  border-bottom: 1px dashed #ccc;
}
</style>