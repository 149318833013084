import colors from './colors';
import DiagramGroup from './diagramGroup';
import { foreignKeySegment } from '../../utils/erModel';

const drawLine = (context, x1, y1, x2, x3, y3, x4) => {
  let _path = context.two.makePath(x1, y1, x2, y1, x3, y3, x4, y3, true);
  _path.fill = colors.TRANSPARENT;
  _path.stroke = colors.BLACK;
  _path.linewidth = 2;
  return _path;
};

const drawSelectionLine = (context, x1, y1, x2, x3, y3, x4) => {
  let _path = context.two.makePath(x1, y1, x2, y1, x3, y3, x4, y3, true);
  _path.fill = colors.TRANSPARENT;
  _path.stroke = colors.TRANSPARENT;
  _path.linewidth = 12;
  return _path;
};

const drawArrow = (context, _x1, _y1, _x2, x3, y3, x4) => {
  let _path = context.two.makePath(x4, y3, x4 + 10 * Math.sign(x3 - x4), y3 - 5, x4 + 10 * Math.sign(x3 - x4), y3 + 5);
  _path.fill = colors.BLACK;
  _path.stroke = colors.BLACK;
  _path.linewidth = 2;
  return _path;
};

class ForeignKeyGroup extends DiagramGroup {
  constructor(id, context) {
    super(id, context);
    this.erLineContainer = this.context.two.makeGroup();
    this.add(this.erLineContainer);
  }
  setLine(_tableA, _tableB, indexA, indexB) {
    const [x1, y1, x2, x3, y3, x4] = foreignKeySegment(_tableA, _tableB, indexA, indexB, 30);
    this.erLineContainer.remove(this.erLine).remove(this.erSelectionLine).remove(this.erArrow);
    this.erLine = drawLine(this.context, x1, y1, x2, x3, y3, x4);
    this.erArrow = drawArrow(this.context, x1, y1, x2, x3, y3, x4);
    this.erSelectionLine = drawSelectionLine(this.context, x1, y1, x2, x3, y3, x4);
    this.erLineContainer.add(this.erLine).add(this.erSelectionLine).add(this.erArrow);
  }
  setSelected(selected) {
    this.erLine.linewidth = selected ? 4 : 2;
    this.erArrow.linewidth = selected ? 4 : 2;
  }
}

export const drawForeignKey = (foreignKey, context) => {
  const scene = context.diagram.scene;
  let _foreignKey = context.diagram.$(foreignKey.getId());
  let _referencingTable = context.diagram.$(foreignKey.getReferencingColumn().getTable().getId());
  let _referencedTable = context.diagram.$(foreignKey.getReferencedColumn().getTable().getId());
  
  if(!_foreignKey) {
    _foreignKey = new ForeignKeyGroup(foreignKey.getId(), context);
    context.diagram.register(foreignKey.getId(), _foreignKey);
    scene.$modelForeignKeys.add(_foreignKey);
  }
  
  _foreignKey.setLine(_referencingTable, _referencedTable, foreignKey.getReferencingColumn().getTableIndex(), foreignKey.getReferencedColumn().getTableIndex());
  _foreignKey.setSelected(context.diagram.selectedItem?.getId() == foreignKey.getId());
};