import colors from './colors';
import DiagramGroup from './diagramGroup';
import DiagramText from './diagramText';
import { QUADRANT, rectQuadrantAbs } from '../../utils/geometry';

const drawGeneralizationTypeText = () => {
  let _text = new DiagramText();
  _text.family = 'Commissioner';
  _text.fill = colors.BLACK;
  _text.size = 16;
  return _text;
};

const drawGeneralizationArrow = context => {
  let _group = context.two.makeGroup();
  let _selectionLine = context.two.makeLine(0, -30, 0, 50);
  _selectionLine.linewidth = 12;
  _selectionLine.stroke = colors.TRANSPARENT;
  let _line = context.two.makeLine(0, -30, 0, 50);
  _line.stroke = colors.BLACK;
  let _arrow = context.two.makePath(-12, -30, 12, -30, 0, -50, false);
  _arrow.fill = colors.TRANSPARENT;
  _arrow.stroke = colors.BLACK;
  _group.add(_selectionLine, _line, _arrow);
  return { _group, _line, _arrow };
};

class GeneralizationParentGroup extends DiagramGroup {
  constructor(id, context) {
    super(id, context);
    this.erSegment = null;
    this.erAnchorPoint = null;
    let generalizationArrow = drawGeneralizationArrow(this.context);
    this.erLine = generalizationArrow._line;
    this.erArrow = generalizationArrow._arrow;
    this.erTypeText = drawGeneralizationTypeText();
    this.erArrowContainer = this.context.two.makeGroup(generalizationArrow._group);
    this.add(this.erArrowContainer, this.erTypeText);
  }
  getAnchorPoint() {
    return this.erAnchorPoint;
  }
  setArrow(_entity, children) {
    this.erArrowContainer.opacity = children.length ? 1 : 0;
    if(children.length) {
      const centroid = children.reduce((s, i) => {
        s.x += (i.getX() / children.length);
        s.y += (i.getY() / children.length);
        return s;
      }, { x: 0, y: 0 });
      let sign;
      const entityRect = _entity.getShape();
      const quadrant = rectQuadrantAbs(centroid.x, centroid.y, entityRect);
      switch(quadrant) {
        case QUADRANT.TOP:
        case QUADRANT.BOTTOM:
          sign = (quadrant == QUADRANT.BOTTOM ? 1 : -1);
          this.erAnchorPoint = { x: 0, y: sign * 150 };
          this.erSegment = {
            entityX: entityRect.x,
            entityY: entityRect.y + sign * entityRect.height / 2,
            anchorX: entityRect.x + this.erAnchorPoint.x,
            anchorY: entityRect.y + this.erAnchorPoint.y
          };
          this.erArrowContainer.rotation = quadrant == QUADRANT.BOTTOM ? 0 : Math.PI;
          this.erArrowContainer.translation.set(0, sign * 100);
          this.erTypeText.translation.set(25, sign * 125);
          break;
        case QUADRANT.LEFT:
        case QUADRANT.RIGHT:
          sign = (quadrant == QUADRANT.RIGHT ? 1 : -1);
          this.erAnchorPoint = { x: sign * (100 + entityRect.width / 2), y: 0 };
          this.erSegment = {
            entityX: entityRect.x + sign * entityRect.width / 2,
            entityY: entityRect.y,
            anchorX: entityRect.x + this.erAnchorPoint.x,
            anchorY: entityRect.y + this.erAnchorPoint.y
          };
          this.erArrowContainer.rotation = sign * -Math.PI / 2;
          this.erArrowContainer.translation.set(sign * (50 + entityRect.width / 2), 0);
          this.erTypeText.translation.set(sign * (entityRect.width / 2 + 70), -15);
          break;
      }
    }
  }
  setType(type) {
    this.erTypeText.value = `(${type.replace('_', ',')})`;
  }
  setSelected(selected) {
    this.erLine.linewidth = selected ? 4 : 2;
    this.erArrow.linewidth = selected ? 3 : 2;
    this.erTypeText.weight = selected ? 600 : 400;
  }
  setToBeRestructured(toBeRestructured) {
    this.erArrow.stroke = toBeRestructured ? colors.RED : colors.BLACK;
    this.erLine.stroke = toBeRestructured ? colors.RED : colors.BLACK;
  }
}

export const drawGeneralizationParent = (generalization, context) => {
  let _generalization = context.diagram.$(generalization.getId());
  const _entity = context.diagram.$(generalization.getEntity().getId());

  const childEntities = generalization.getChildren().map(g => g.getEntity());
  
  const restructuring = context.diagram.step == 'erRestructuring';

  if(!_generalization) {
    _generalization = new GeneralizationParentGroup(generalization.getId(), context);
    context.diagram.register(generalization.getId(), _generalization);
    _entity.getGeneralization().add(_generalization);
  }

  _generalization.setArrow(_entity, childEntities);
  _generalization.setType(generalization.getType());
  _generalization.setSelected(context.diagram.selectedItem?.getId() == generalization.getId());
  
  _generalization.setToBeRestructured(restructuring && generalization.getSupportedFunctionalities().restructuring);
};