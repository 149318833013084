<template>
  <div>
    <div class="align"><button class="close-btn" @click="handleBack()">X</button></div>
    <div class="about"> 
      <h2>About DesignQT</h2>
      <p class="subheader"><a href="https://designer.polito.it">https://designer.polito.it</a></p>
      <p>DesignQT is a software platform for educational purposes<br />developed at Politecnico di Torino - <a target="_blank" href="https://dbdmg.polito.it/wordpress/">DBDMG</a>.</p>
      <p>For more information, feel free to <a v-bind:href="link">email us</a>.</p>
      <p class="version">version 1.0.258</p>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { mainStore } from '../store/main';
import config from '../config';

export default {
  name: 'About',
  methods:{
    handleBack(){
      mainStore.closeModal();
    }
  },
  setup() {
    const { emailTo, emailSubject } = config.about;
    const link = computed(() => {
      return `mailto:${emailTo}?subject=${emailSubject}`;
    })

    return {
      link
    };
  }
};
</script>

<style scoped>
.align{
  text-align: right;
  margin-bottom: 0px;
  margin-top: 7px;
}
.close-btn{
  background-color: transparent !important;
  font-size: 18px;
  font-weight: 800;
  padding: 4px 16px 0px 0px;
}
h2 {
  margin-bottom: 1em;
  margin-top: 0px;
}
svg {
  width: 16px;
  vertical-align: bottom;
}
.about {
  position: relative;
  text-align: center;
  padding: 0px 40px 40px 40px;
}
.subheader {
  margin: 0.5em 0 1.5em 0;
  font-size: 0.9em;
}
.version {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  font-size: 0.8em;
  color: #888;
}
@media all and (max-width: 720px) {
  .about {
    font-size: 0.85em;
    padding: 10px 20px 40px 20px;
  }
}
</style>