import events from './events';
import config from '../../config';

class Anaytics {
  constructor() {
    this.active = false;
    this.events = [];
    this.startTime = null;
    this.syncTimeout = null;
  }
  _send() {
    clearTimeout(this.syncTimeout);
    this.syncTimeout = null;

    try {
      const data = {
        id: this.id,
        start: this.startTime,
        events: this.events
      }
      fetch(config.analytics.apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });
  
      this.events = [];
      this.startTime = null;
    } catch(err) {
      console.error(err);
    }
  }
  init(uuid) {
    this.id = uuid;
    this.active = true;
    this.event(events.APP_STARTUP);
  }
  event(type, data = {}) {
    if(!this.active)
      return;
      
    const now = new Date().getTime();

    if(this.startTime == null)
      this.startTime = now;

    this.events.push({ e: type, t: now - this.startTime, ...data });

    if(this.syncTimeout == null)
      this.syncTimeout = setTimeout(this._send.bind(this), config.analytics.syncPeriodSeconds * 1000);
  }
}

export default new Anaytics();