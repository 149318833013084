export default class ForeignKey {
  constructor(model, id, referencingColumnId, referencedColumnId) {
    this.__type = 'ForeignKey';
    this._model = () => model;
    this._id = id;
    this._referencingColumnId = referencingColumnId;
    this._referencedColumnId = referencedColumnId;
  }
  static fromObject(model, obj) {
    return new ForeignKey(model, obj._id, obj._referencingColumnId, obj._referencedColumnId);
  }
  getId() {
    return this._id;
  }
  getReferencingColumn() {
    return this._model().getItemById(this._referencingColumnId);
  }
  getReferencedColumn() {
    return this._model().getItemById(this._referencedColumnId);
  }
  getSupportedFunctionalities() {
    return {};
  }
  getAllowedFunctionalities() {
    return {};
  }
}