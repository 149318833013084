import { writePngDpi } from 'png-chunk-phys';

import { draw } from './draw';

import config from '../../config';

export const exportToPng = async (context, canvas) => {
  const imageZoom = config.pngExport.zoom;
  const modelScene = context.diagram.scene.$model;
  const scale = modelScene.scale;
  const rect = modelScene.getBoundingClientRect();
  const width = Math.max(config.pngExport.minWidth, (rect.width / scale) * imageZoom + 200);
  const height = Math.max(config.pngExport.minHeight, (rect.height / scale) * imageZoom + 200);

  canvas ? canvas.innerHTML = '' : '';

  let pngContext = {
    two: new Two({ type: Two.Types.canvas, width, height }).appendTo(canvas),
    diagram: {
      model: context.diagram.model,
      transparent: true
    },
    transform: {
      scale: imageZoom,
      offsetX: 0,
      offsetY: 0
    },
    canvas: { width, height }
  };

  draw(pngContext, true);

  const pngModelScene = pngContext.diagram.scene.$model;
  const pngRect = pngModelScene.getBoundingClientRect();
  pngContext.transform.offsetX = width / 2 - (pngRect.left + pngRect.width / 2);
  pngContext.transform.offsetY = height / 2 - (pngRect.top + pngRect.height / 2);
  
  draw(pngContext, true);

  return new Promise(function(resolve) {
    const c = canvas.getElementsByTagName('canvas')[0];
    const ctx = c.getContext('2d');
    const imageData = ctx.getImageData(0, 0, c.width, c.height);
    const data = imageData.data;
    for(let i = 0; i < data.length; i += 4) {
      data[i+3] = 255 - (data[i] + data[i+1] + data[i+2]) / 3;
      data[i] = data[i+1] = data[i+2] = 0;
    }
    ctx.putImageData(imageData, 0, 0);
    c.toBlob(blob => {
      blob.arrayBuffer().then(buffer => {
        resolve(new Blob([writePngDpi(buffer, config.pngExport.dpi)]));
      });
    }, 'image/png');
  });
};