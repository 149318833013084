export default class DiagramGroup extends Two.Group {
  constructor(id, context) {
    super();
    this.erId = id;
    this.context = context;
  }
  setVisible(visible) {
    this.opacity = visible ? 1 : 0;
  }
  setPosition(x, y) {
    this.translation.set(x, y);
  }
}