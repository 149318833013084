let movingItem = false;
import { ref} from 'vue'

export const events = {
  childComponent: ref(null),

  onMove(x, y, ctx) {
    if(!ctx.diagram.selectedTool || !['entity', 'unaryInput', 'unaryInputPi', 'binaryInput', 'natural-join', 'semi-join', 'asemi-join', 'difference', 'union', 'division', 'intersection', 'result', ].includes(ctx.diagram.selectedTool))
      return false;
    ctx.diagram.scene.$overlays.setPosition(x, y);
    ctx.diagram.scene.$overlays.showOverlay(ctx.diagram.selectedTool);
   
  },
  onClick(x, y, _, ctx) {
    if(ctx.diagram.selectedTool && !['connect', 'parent'].includes(ctx.diagram.selectedTool) && ctx.callbacks?.addItem) {
      const modelScene = ctx.diagram.scene.$model;
      ctx.callbacks.addItem(
        ((x - modelScene.translation.x) / modelScene.scale) - ctx.canvas.width / 2,
        ((y - modelScene.translation.y) / modelScene.scale) - ctx.canvas.height / 2
      );

      /*console.log(ctx.diagram.selectedItem);

      if( ctx.diagram.selectedTool == 'entity'){
        //ctx.callbacks.toggleMenu('renaming');
        //console.log(ctx.diagram.selectedTool== 'entity')
        console.log("CLICK AFTER POSA");   
        console.log(ctx.callbacks?.toggleMenu)   ;
            ctx.callbacks?.toggleMenu('schema');
      }*/
      return false;
    }
  },
  onKey(key, ctrl, ctx, shift,e) {
    if(ctx.diagram.selectedItem) {
      if(key == 46 /* delete */ && ctx.callbacks?.deleteItem)
        ctx.callbacks.deleteItem();
        if(ctx.callbacks?.toggleMenu ){
          if(ctx.diagram.selectedItem.__type =='Entity'){
        if(shift && key == 82 /* r */ ){
          e.preventDefault();
          ctx.callbacks.toggleMenu('renaming');
        }
        if(shift  && key == 83 /* s */ ){
          e.preventDefault();
          ctx.callbacks.toggleMenu('schema');   
        }  
        if(shift && key == 73 /* i */  ){
          e.preventDefault();
          ctx.callbacks.toggleMenu('info');
        }
          
        if(shift && key == 68 /* d */ ){
          e.preventDefault();
          ctx.callbacks.selectTool('copy');
        }
      }
      if(ctx.diagram.selectedItem.__type =='Relationship'){
        //console.log( ctx.diagram.selectedItem.getAllowedFunctionalities().entityParticipation.ok);
        if(shift && key == 67 /* c */ && ctx.diagram.selectedItem.getAllowedFunctionalities().entityParticipation.ok) {
          e.preventDefault();
          ctx.callbacks.selectTool('connect');
        }
        if(shift && key == 80 /* p */ ){
          e.preventDefault();
          ctx.callbacks.toggleMenu('renaming');
        }
      }
      if(ctx.diagram.selectedItem.__type =='Participation'){
        if(shift && key == 78 /* n */ ) {
          e.preventDefault();
          ctx.callbacks.toggleMenu('renaming');
        }
      }
        }
    }
    if(ctx.diagram.selectedTool && ctx.callbacks?.selectTool) {
      if(key == 27 /* Esc */)
        ctx.callbacks.selectTool(null);
    }
    if(ctrl && key == 90 /* z */ && ctx.callbacks?.undo)
      ctx.callbacks.undo();
    if(ctrl && key == 89 /* y */ && ctx.callbacks?.redo)
      ctx.callbacks.redo();
    if(ctrl && key == 83 /* s */)
      e.preventDefault();
  },
  onPan(dx, dy, ctx) {
    if(ctx.diagram.selectedTool)
      ctx.diagram.scene.$overlays.showOverlay(null);
    else if(ctx.diagram.selectedItem) {
      movingItem = true;
      const modelScene = ctx.diagram.scene.$model;
      if(ctx.callbacks?.moveItem)
        ctx.callbacks.moveItem(dx / modelScene.scale, dy / modelScene.scale);
      return false;
    }
  },
  onPanEnd(ctx) {
    if(movingItem) {
      movingItem = false;
      if(ctx.callbacks?.endMoveItem)
        ctx.callbacks.endMoveItem();
    }
  }
};