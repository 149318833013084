<template>
  <CodeModelLayout codeHeaderTitle="Code" modelHeaderTitle="Model">
    <template v-slot:code>
      <ERCodeEditor />
    </template>
    <template v-slot:model>
      <ERModelEditor />
    </template>
  </CodeModelLayout>
</template>

<script>
import CodeModelLayout from './CodeModelLayout';
import ERCodeEditor from './ERCodeEditor';
import ERModelEditor from './ERModelEditor';

export default {
  name: 'ERDesign',
  components: {
    CodeModelLayout,
    ERCodeEditor,
    ERModelEditor
  }
};
</script>