import Entity from "./entity";

export default class Participation {
  constructor(model, id, entityId, tableId, relationshipId, cardinality, externalIdentifier, role, comment) {
    this.__type = 'Participation';
    this._model = () => model;
    this._id = id;
    this._entityId = entityId;
    this._tableId = tableId;
    this._relationshipId = relationshipId;
    this._cardinality = cardinality;
    this._externalIdentifier = externalIdentifier;
    this._role = role;
    this._comment = comment;
  }
  static fromObject(model, obj) {
    return new Participation(model, obj._id, obj._entityId, obj._tableId, obj._relationshipId, obj._cardinality, obj._externalIdentifier, obj._role, obj._comment);
  }
  getId() {
    return this._id;
  }
  getEntity() {
    return this._entityId ? this._model().getItemById(this._entityId) : null;
  }
  getTable() {
    return this._tableId ? this._model().getItemById(this._tableId) : null;
  }
  getGenericET() {
    return this.getEntity() || this.getTable();
  }
  getRelationship() {
    return this._model().getItemById(this._relationshipId);
  }
  getCardinality() {
    return this._cardinality;
  }
  getMaxCardinality() {
    return this.getCardinality().split('_')[1];
  }
  isExternalIdentifier() {
    return this._externalIdentifier;
  }
  getRole() {
    return this._role;
  }
  getComment() {
    return this._comment;
  }

  setComment(c){
    return this._comment = c;
  }
  isRelationship(){
    return false;
  }
  isEntity(){
    return false;
  }
  getUnaryIndex() {
    const relationship = this.getRelationship();
    if(relationship.isRecursive())
      return relationship.getParticipations().map(p => p.getId()).indexOf(this.getId());
    else
      return null;
  }
  getSupportedFunctionalities() {
    let typeOfArch=false;
    if(this.getRelationship()._typeOfInput=='semi-join' || this.getRelationship()._typeOfInput=='naturalJoin' || this.getRelationship()._typeOfInput=='division'|| this.getRelationship()._typeOfInput=='difference' || this.getRelationship()._typeOfInput=='union'|| this.getRelationship()._typeOfInput=='intesection' || this.getRelationship()._typeOfInput=='binary'){
      typeOfArch=true;
    }
    return {
      externalIdentifier: true,
      cardinality: true,
      cardinalityN: true,
      role: true,
      temporarySchema: true,
      showTypeOfArch: typeOfArch,
      comment: true
    };
  }

    getAllowedFunctionalities() {
    let externalIdentifier = { ok: true };
    /*if(this.getEntity()?.hasKey())
      externalIdentifier = { ok: false, error: 'The involved entity already has internal identifiers.' };
    if(this.getRelationship().isRecursive())
      externalIdentifier = { ok: false, error: 'External identification is not possible with recursive relationships.' };
*/
    let cardinality = { ok: true };
    /*
    if(this.isExternalIdentifier())
      cardinality = { ok: false, error: 'For an externally-identified entity, the cardinality of the participation is implicitly (1,1).' };
*/
    let temporarySchema ={ok: true};
    let typeOfArch = {ok: true};
    return {
      externalIdentifier,
      cardinality,
      temporarySchema,
      typeOfArch,
    };
  }
  __beforeDelete() {
   if(this.getEntity().isRelationship()){
      this.getEntity().decreaseParticipationCount();
   }
  }
  setCardinality(cardinality) {
    this._cardinality = cardinality;
  }
  setExternalIdentifier(externalIdentifier) {
    this._externalIdentifier = externalIdentifier;
    if(this.isExternalIdentifier())
      this.setCardinality('1_1');
    else if(!this.getEntity().getParticipations().some(p => p.isExternalIdentifier()))
      this.getEntity().resetExternalIdentifierAttributes();
  }
  setRole(role) {
    role = role.replace(/\s/, '_').replace(/[^a-zA-Z0-9_]/, '').replace(/^\d+/, '');
    this._role = role;
    return role;
  }
  isInner(){
    //console.log(this.getRelationship().getParticipations().length)
    if(this.getRelationship().getParticipations().length==1)

      return 'Outer';
    else   if(this.getRelationship().getParticipations().length==2)
      return 'Inner';
  }
  toERCode() {
    let code;
    if(this.getEntity() instanceof Entity)
      code= `${this.getEntity().getName()}`;
    else 
      code= `${this.getEntity().getTypeToDraw()} ${this.getEntity().getName()}`;
    //console.log("rel" +this.getEntity().getTypeToDraw());   //è la relazione che devo disegnare
    //console.log(this.getEntity() instanceof Entity);
    /*
    if(this.getRole())
    code += ` "${this.getRole()}"`;
    code += ': ' + this.getCardinality().replace('_', '..').replace(/0/g, 'zero').replace(/1/g, 'one').replace(/N/g, 'many');
    if(this.isExternalIdentifier())
      code += ' external';
    */
    return code;
  }
}