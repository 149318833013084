import Attribute from './attribute';
import Generalization from './generalization';
import GeneralizationChild from './generalizationChild';
import Participation from './participation';
import Relationship from './relationship';

export default class Entity {
  constructor(model, id, name, x, y, generatedFromMultivaluedAttribute) {
    this.__type = 'Entity';
    this._model = () => model;
    this._id = id;
    this._name = name;
    this._x = Math.round(x);
    this._y = Math.round(y);
    this._mag = generatedFromMultivaluedAttribute;
  }
  static fromObject(model, obj) {
    return new Entity(model, obj._id, obj._name, obj._x, obj._y, obj._mag);
  }
  getId() {
    return this._id;
  }
  getName() {
    return this._name;
  }
  getX() {
    return this._x;
  }
  getY() {
    return this._y;
  }
  getAttributes() {
    if(this._model().getItemsWhere(i => i instanceof Attribute && i.getParent().getId() == this.getId()))
      return this._model().getItemsWhere(i => i instanceof Attribute && i.getParent().getId() == this.getId());
    else 
      return [];
  }
  hasAttributes() {
    return this.getAttributes().length;
  }
  getParticipations() {
    return this._model().getItemsWhere(i => i instanceof Participation && i.getEntity()?.getId() == this.getId());
  }
  hasKey() {
    return this.getAttributes().some(a => a.isIdentifier());
  }
  hasExternalIdentifier() {
    return this.getParticipations().some(p => p.isExternalIdentifier());
  }
  hasIdentifier() {
    return this.hasKey() || this.hasExternalIdentifier();
  }
  generalization() {
    return this._model().getItemWhere(i => i instanceof Generalization && i.getEntity().getId() == this.getId());
  }
  generalizationChild() {
    return this._model().getItemWhere(i => i instanceof GeneralizationChild && i.getEntity().getId() == this.getId());
  }
  getParentEntity() {
    return this.generalizationChild()?.getGeneralization().getEntity();
  }
  hasChildEntities() {
    return !!this.generalization();
  }
  wasGeneratedFromMultivaluedAttribute() {
    return this._mag;
  }
  canAddGeneralization(parent) {
    if(!(parent instanceof Entity))
      return { result: false, error: 'The selected item is not an entity.' };
    if(parent.getParentEntity())
      return { result: false, error: 'The selected entity is already child in a generalization, and cannot be at the same time parent of the current entity.' };
    return { result: true };
  }
  canBeTranslated() {
    return this.hasKey() || !this.getParticipations().filter(p => p.isExternalIdentifier()).flatMap(p => p.getRelationship().getParticipations()).some(p => p.getEntity() && p.getEntity().getId() != this.getId());
  }
  isEntity(){
    return true;
  }
  isRelationship(){
    return false;
  }
  getSupportedFunctionalities() {
    return {
      parentEntity: true,
      attribute: true,
      moving: true,
      renaming: true,
      translating: true,
      temporarySchema: false,
    };
  }
  getAllowedFunctionalities() {
    let parentEntity = { ok: true };
    if(this.getParentEntity())
      parentEntity = { ok: false, error: 'An entity cannot have more than one parent.' };
    if(this.hasChildEntities())
      parentEntity = { ok: false, error: 'An entity cannot be both parent and child at the same time.' };

    let attribute = { ok: true };
    let temporarySchema ={ ok: false}

    return {
      attribute,
      parentEntity,
      temporarySchema,
    };
  }
  getErrors() {
    let errors = [];
    return errors;
  }
  getWarnings() {
    let warnings = [];

   /* if(!this.getAttributes().some(a => !a.isIdentifier()))
      warnings.push({ scope: 'erDesign', description: `The entity has no attributes (apart from identifiers).` });*/
    
    return warnings;
  }
  __beforeDelete() {
    for(let attribute of this.getAttributes())
      this._model().deleteItem(attribute.getId());

    for(let participation of this.getParticipations())
      this._model().deleteItem(participation.getId());

    const generalization = this.generalization();
    if(generalization)
      this._model().deleteItem(generalization.getId());

    const generalizationChild = this.generalizationChild();
    if(generalizationChild)
      this._model().deleteItem(generalizationChild.getId());
  }
  /*setName(name) {
    name = name.replace(/\s/g, '_').replace(/[^a-zA-Z0-9_]/g, '').replace(/^\d+/g, '').toUpperCase();
    if(name.length)
      this._name = name;
    return name;
  }*/
  setName(name) {
    let precName= this._name;
    this._name = name;
    //attributi della tabella
    let attr;
    let idslist;

    if(this.getAttributes()){
      idslist = this.getAttributes().map(e=>e.getId());
      for (let i in idslist){
        attr=this.getAttributes().filter(e=> e.getId() == idslist[i]).map(e=>e.getName().split('.')[1]); 
        this.getAttributes().filter(e=> e.getId() == idslist[i]).map(e=>e.setName(name+'.' + attr));
      }
    }
     //if(this.getAttributes()==''){
      else{
        for (let i in idslist){
          let attr =this.getTempAttrs().filter(e=> e.getId() == idslist[i]).map(e=>e.getName().split('.')[1]); 
          this.getTempAttrs().filter(e=> e.getId() == idslist[i]).map(e=>e.setName(name+ '.' + attr));
        }
      }
    
    
    //cambaire i nomi e i predicati
    if(this._model().getItemsWhere(i => i instanceof Relationship)!=[]){ //&& this._model().getItemsWhere(i => i instanceof Relationship)[0].getTempAttrs()!=''
    //if(this._model().getItemsWhere(i => i instanceof Relationship)[0].getTempAttrs()!=[] ){
    //if( this._model().getItemsWhere(i => i instanceof Relationship)[0].getTempAttrs()!=[] ) {
    //if(this._model().getItemsWhere(i => i instanceof Relationship)[0].getTempAttrs()[0]!=[]){
    //console.log(this._model().getItemsWhere(i => i instanceof Relationship)[0].getTempAttrs()!='');
    //console.log(this._model().getItemsWhere(i => i instanceof Relationship)[0].getTempAttrs()!=[]);
    //console.log(this._model().getItemsWhere(i => i instanceof Relationship)[0].getTempAttrs()!='');
    //console.log(this._model().getItemsWhere(i => i instanceof Relationship)[this._model().getItemsWhere(i => i instanceof Relationship).length-1])
    //let tempAttr1= this._model().getItemsWhere(i => i instanceof Relationship).map(e=>e.getTempAttrs());
    //let tempAttr= this._model().getItemsWhere(i => i instanceof Relationship).map(e=>e.getTempAttrs().map(i =>  i.split('.')[0]==precName ? name + '.' + i.split('.')[1] : i));
    
    let idslist=[];   
    idslist=this._model().getItemsWhere(i => i instanceof Relationship).map(e=>e.getId());  //console.log(this._model().getItemsWhere(i => i instanceof Relationship).map(e=>e.getTempAttrs().map(i => name + '.' + i.split('.')[1])));
        
    let tempAttr;
    for (let i in idslist){
      this._model().getItemById(idslist[i]).getTempAttrs() ? tempAttr = this._model().getItemById(idslist[i]).getTempAttrs().map(i => i.split('.')[0]==precName ?  name + '.' + i.split('.')[1] : i) : ''; //i.split('.')[0]==precName ? name + '.' + i.split('.')[1] : i
      tempAttr ? this._model().getItemById(idslist[i]).setTempAttrs(tempAttr) : '';
    }

   // row+= this._objname[n].op + ' ' +this._objname[n].attrName + ' ' + this._objname[n].operator + ' ' + this._objname[n].attrName1 + ' ' + this._objname[n].condition  + ' ' ;}
   // if(tempAttr1.filter(e=>e != [])!=''){
   for (let i in idslist){
      if(this._model().getItemById(idslist[i]).getObjName()){
        //console.log(this._model().getItemById(idslist[i]).getObjName()); 
        //let tempPred = this._model().getItemById(idslist[i]).getObjName().map(e => e.attrName.split('.')[0]==precName ?  this._model().getItemById(idslist[i]).setObjNameAttr(e.id-1, name + '.' + e.attrName.split('.')[1] ):'');
        this._model().getItemById(idslist[i]).getObjName().map(e => e.attrName1.split('.')[0]==precName ?  this._model().getItemById(idslist[i]).setObjNameAttr1(e.id-1, name + '.' + e.attrName1.split('.')[1] ):'');
        this._model().getItemById(idslist[i]).setObjName(this._model().getItemById(idslist[i]).getObjName());
        //this._model().getItemById(idslist[i]).setTempAttrs(tempAttr);
    }
  }
}
    //problema 1  fare per tutti gli id
    //problema 2 sostituire solo dove c'era il nome vecchio
    //pbm 3 mettere l'if
    return name;
  }
  move(dx, dy) {
    this._x = Math.round(this._x + dx);
    this._y = Math.round(this._y + dy);
  }
  resetExternalIdentifierAttributes() {
    for(let attribute of this.getAttributes())
      attribute.setExternalIdentifier(false);
  }
  toERCode() {
    let code = `table ${this.getName()}`;
//disegna tabella
    if(this.hasAttributes()) {
      code += ' {\n    ';
      code += this.getAttributes().map(a => a.toERCode()).join(',\n    ');
      code += '\n}';
    }
    return code;
  }
}