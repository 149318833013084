<template>
  <div class="er-restructuring-container">
    <div class="restruct flex flex-col">
      <div class="header"><PanelTitle>Restructuring</PanelTitle></div>
      <ERModelRestruct />
    </div>
  </div>
</template>

<script>
import ERModelRestruct from './ERModelRestruct';
import PanelTitle from './PanelTitle';

export default {
  name: 'ERRestructuring',
  components: {
    ERModelRestruct,
    PanelTitle
  }
};
</script>

<style scoped>
.er-restructuring-container {
  display: flex;
  height: 100%;
  overflow: hidden;
}
.restruct {
  flex: 1;
  padding: 10px 20px;
}
.header {
  color: #aaa;
}
</style>