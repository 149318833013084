import colors from './colors';
import DiagramGroup from './diagramGroup';
import DiagramText from './diagramText';

const drawColumnText = () => {
  let _text = new DiagramText();
  _text.family = 'Commissioner';
  _text.fill = colors.BLACK;
  _text.size = 18;
  _text.alignment = 'left';
  _text.translation.set(35, 0);
  return _text;
};

class ColumnGroup extends DiagramGroup {
  constructor(id, context) {
    super(id, context);
    this.erText = drawColumnText();
    this.add(this.erText);
  }
  setName(name) {
    this.erText.value = name;
  }
  setIdentifier(identifier) {
    this.erText.decoration = identifier ? 'underline' : 'none';
  }
  setSelected(selected) {
    this.erText.weight = selected ? 600 : 400;
  }
}

export const drawColumn = (column, context) => {
  let _column = context.diagram.$(column.getId());
  const _table = context.diagram.$(column.getTable().getId());
  
  if(!_column) {
    _column = new ColumnGroup(column.getId(), context);
    context.diagram.register(column.getId(), _column);
    _table.getColumns().add(_column);
  }
  
  _column.setName(column.getName() + (column.isNullable() ? '*' : ''));
  _column.setIdentifier(column.isIdentifier());
  _column.setPosition(0, 94 + 28 * column.getTableIndex());
  _column.setSelected(context.diagram.selectedItem?.getId() == column.getId());
  
  _table.setDimensions();
};