<template>
  <p><slot></slot></p>
</template>

<script>
export default {
  name: 'PanelTitle'
};
</script>

<style scoped>
p {
  user-select: none;
  font-size: 0.8em;
  font-weight: 300;
  text-transform: uppercase;
}
</style>