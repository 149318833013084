export const translateOneToManyTypeARelationship = function(model, relationshipId) {
  const relationship = model.getItemById(relationshipId);
  const oneParticipation = relationship.getParticipations().find(p => p.getCardinality() == '1_1');
  const manyParticipation = relationship.getParticipations().find(p => p.getCardinality().endsWith('_N'));
  const tableA = model.getItemById(oneParticipation.getTable().getId());
  const tableB = model.getItemById(manyParticipation.getTable().getId());
  let columnIndex = tableA.getColumns().length;
  for(let column of tableB.getColumns().filter(c => c.isIdentifier())) {
    const columnName = `${column.getTable().getName()}_${column.getName()}${manyParticipation.getRole() ? '_' + manyParticipation.getRole() : ''}`;
    const genColumn = model.addColumn(columnName, tableA.getId(), columnIndex++, false, false);
    model.addForeignKey(genColumn.getId(), column.getId());
  }
  for(let attribute of relationship.getAttributes())
    model.addColumn(attribute.getName(), tableA.getId(), columnIndex++, false, attribute.getMinCardinality() == '0');
  model.deleteItem(relationship.getId());
};

export const translateOneToManyTypeBRelationship = function(model, relationshipId, createTable) {
  const relationship = model.getItemById(relationshipId);
  const oneParticipation = relationship.getParticipations().find(p => p.getCardinality() == '0_1');
  const manyParticipation = relationship.getParticipations().find(p => p.getCardinality().endsWith('_N'));
  const tableA = model.getItemById(oneParticipation.getTable().getId());
  const tableB = model.getItemById(manyParticipation.getTable().getId());
  let columnIndex;
  if(createTable) {
    columnIndex = 0;
    const table = model.addTable(relationship.getName(), relationship.getX(), relationship.getY());
    for(let column of tableA.getColumns().filter(c => c.isIdentifier())) {
      const columnName = `${column.getTable().getName()}_${column.getName()}${oneParticipation.getRole() ? '_' + oneParticipation.getRole() : ''}`;
      const genColumn = model.addColumn(columnName, table.getId(), columnIndex++, true, false);
      model.addForeignKey(genColumn.getId(), column.getId());
    }
    for(let column of tableB.getColumns().filter(c => c.isIdentifier())) {
      const columnName = `${column.getTable().getName()}_${column.getName()}${manyParticipation.getRole() ? '_' + manyParticipation.getRole() : ''}`;
      const genColumn = model.addColumn(columnName, table.getId(), columnIndex++, false, false);
      model.addForeignKey(genColumn.getId(), column.getId());
    }
    for(let attribute of relationship.getAttributes())
      model.addColumn(attribute.getName(), table.getId(), columnIndex++, false, attribute.getMinCardinality() == '0');
  } else {
    columnIndex = tableA.getColumns().length;
    for(let column of tableB.getColumns().filter(c => c.isIdentifier())) {
      const columnName = `${column.getTable().getName()}_${column.getName()}${manyParticipation.getRole() ? '_' + manyParticipation.getRole() : ''}`;
      const genColumn = model.addColumn(columnName, tableA.getId(), columnIndex++, false, true);
      model.addForeignKey(genColumn.getId(), column.getId());
    }
    for(let attribute of relationship.getAttributes())
      model.addColumn(attribute.getName(), tableA.getId(), columnIndex++, false, true);
  }
  model.deleteItem(relationship.getId());
};