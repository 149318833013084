
<template>
  <div class="menu toolbox">
    <div><ToolboxButton text="Undo" icon="undo" :disabled="!canUndo" @click="mainStore.undo()" /></div>
    <div><ToolboxButton text="Redo" icon="redo" :disabled="!canRedo" @click="mainStore.redo()" /></div>
     
    <div class="sep"></div>
    
    <div v-if="!selectedItem">
      <div><ToolboxButton text="Table" icon="entity" :selected="selectedTool == 'entity'" @click="store.toggleSelectedTool('entity')" /></div> <!-- :addedEntity="handleAdd()" -->
      <div><ToolboxButton text="Select" icon="sigma" :selected="selectedTool == 'unaryInput'" @click="store.toggleSelectedTool('unaryInput')" /></div>
      <div><ToolboxButton text="Project" icon="pi" :selected="selectedTool == 'unaryInputPi'" @click="store.toggleSelectedTool('unaryInputPi')" /></div> 
    
      <div class="submenu-container">
        <div><ToolboxButton text="Join" icon="join" :selected="showJoinMenu"  @click="store.toggleMenu('join')"  /></div>
        <div class="submenu-align">
          <div class="submenu" v-if="showJoinMenu" >
            <div><ToolboxButton text="Natural join" icon="join"  @click="store.toggleSelectedTool('natural-join')" /></div>
            <div><ToolboxButton text="Theta join" icon="join"  @click="store.toggleSelectedTool('binaryInput')" /></div>
            <div><ToolboxButton text="Semi join" icon="semi_join" @click="store.toggleSelectedTool('semi-join')" /></div>
            <div><ToolboxButton text="Anti semi join" icon="anti_semi_join" @click="store.toggleSelectedTool('asemi-join')" /></div>
          </div>
        </div>
      </div>    
     
      <div class="submenu-container">
        <div><ToolboxButton text="Others" icon="others" :selected="showOthersMenu"  @click="store.toggleMenu('others')"  /></div>
        <div class="submenu-align">
          <div class="submenu" v-if="showOthersMenu" >
          <div><ToolboxButton text="Difference" icon="difference" @click="store.toggleSelectedTool('difference')" /></div>
          <div><ToolboxButton text="Division" icon="division" @click="store.toggleSelectedTool('division')" /></div>
          <div><ToolboxButton text="Union" icon="union" @click="store.toggleSelectedTool('union')" /></div>
          <div><ToolboxButton text="Intersect" icon="intersection" @click="store.toggleSelectedTool('intersection')" /></div>
          </div>
        </div>
      </div>
      <div><ToolboxButton text="Result" icon="result" errorTooltip='Cannot have more than one RESULT per query'  v-bind:disabled="false" :selected="selectedTool == 'result'" @click="store.toggleSelectedTool('result')" /></div> 
      <!--!showCanClickResult-->
    </div>

    <div v-if="selectedItem" >
      <div v-if="supported.entityParticipation">
        <ToolboxButton text="Connect" icon="connect_line" :errorTooltip="allowed.entityParticipation.error" :disabled="!allowed.entityParticipation.ok" :selected="selectedTool == 'connect'" @click="store.toggleSelectedTool('connect')" />
          <div class="rename-container" v-if="supported.renamingPi && allowPredicate()">
            <ToolboxButton text="Predicate" icon="rename"  @click="store.toggleMenu('renaming')" />
              <div class="rename-input-container" v-if="showRenamingInput">
                <PiPredicate/>
              </div>
          </div>
          <div class="rename-container" v-if="supported.renaming && allowPredicate()">
            <ToolboxButton text="Predicate" icon="rename" :selected="showRenamingInput"  @click="store.toggleMenu('renaming'), this.handleClick()" />
              <div class="rename-input-container" v-if="showRenamingInput" > <!-- @input -->
               <RAPredicates/>              
              </div>
          </div>
      </div>      

      <div v-if="supported.temporarySchema && allowSchema()">
        <ToolboxButton text="Show schema" icon="table_temp" :selected="showTemp" :disabled="!allowed.temporarySchema.ok"  @click="store.toggleMenu('infoTemp')" />
        <div v-if="showTemp">
          <RAInfoTemp />
        </div>
      </div>

      <div class="rename-container"  v-if="supported.comment">
        <ToolboxButton text="Notes" icon="comment" :selected="showRenamingInput" @click="store.toggleMenu('renaming')" />
        <div class="rename-input-container" v-if="showRenamingInput">
          <input type="text"  :value="this.getSelectedItem().getComment()? this.getSelectedItem().getComment() : ''" spellcheck="false"  placeholder=" Add notes" v-focusselect="showRenamingInput" @input="addComment($event)" /></div>
          <!--<textarea :v-model="this.getSelectedItem().getComment()"></textarea>-->
      </div>
      <div class="rename-container"  v-if="supported.comment && this.getSelectedItem().getComment()!=''">
        <ToolboxButton text="Notes info" icon="info" :selected="showCommentInfo" @click="store.toggleMenu('commentInfo')" />
        <div class="rename-input-container" v-if="showCommentInfo">
          <div> {{placeholder=this.getSelectedItem().getComment() }} </div> </div>
      </div> 

      <div v-if="supported.attribute">
        <div></div>
        <div class="rename-container" v-if="supported.renaming">
          <ToolboxButton text="Rename" icon="rename" :selected="showRenamingInput " @click="store.toggleMenu('renaming')" />
        <div class="rename-input-container" v-if="showRenamingInput">
          <input type="text" :value="this.getSelectedItem().getName().includes('Table') ? '' : this.getSelectedItem().getName() " spellcheck="false" placeholder="New name" v-focusselect="showRenamingInput" @input="renameItem($event)" @keydown="onRenameKeydown($event)" />
        </div>
        </div> 

      <!-- <div class="rename-container" v-if="supported.renaming" @dblclick="renameItem()">
        <div class="rename-input-container">
          <input type="text"  :value="this.getSelectedItem().getName().includes('Table') ? '' : this.getSelectedItem().getName() " spellcheck="false" placeholder="New name" v-focusselect="true" @input="renameItem($event)" @keydown="onRenameKeydown($event)" />
        </div>
      </div> -->       

        <ToolboxButton text="Schema" icon="table_plus" :selected="showSchema" @click="store.toggleMenu('schema')"/>
          <div v-if="showSchema">
            <RATableSchema />
          </div>        
        <div>
          <ToolboxButton text="Info" icon="table" :selected="showInfo" @click="store.toggleMenu('info')" />
          <div v-if="showInfo">
            <RAInfoSchema />
          </div>
          <div></div>
        </div>
        <div>
          <ToolboxButton text="Duplicate" icon="table_copy" :selected="selectedTool == 'copy'" @click="store.toggleSelectedTool('copy')" />
          <div></div>
        </div>
      </div>     
      <div class="sep"></div>      
      <div><ToolboxButton text="Delete" icon="delete" :errorTooltip="'Delete upper elements before'" :disabled="!allowDelete()" @click="store.deleteItem()" /></div>
    </div> 

    <div v-if="!selectedItem">
      <div class="sep"></div>
      <ToolboxButton  ref="childComponent" text="Save"  icon="save"  @click="saveToFile()" errorTooltip="The model must be non-empty to be saved"   @input-proj="inputProj($event)"/>
      
      <div class="submenu-container">
        <ToolboxButton text="Export" icon="export" :selected="showExportMenu" @click="store.toggleMenu('export')" />
        <div class="submenu-align">
          <div class="submenu" v-if="showExportMenu">
            <div><ToolboxButton text="PNG" icon="exportree" errorTooltip="The model must be non-empty in order for you to export png" :disabled="!canExport" @click="saveAsPng()" /></div>
            <div><ToolboxButton text="Notes" icon="notes" @click="saveAsNotes()" errorTooltip="Notes must be non-empty in order for you to export them" :disabled="!mainStore.getEditorData() || mainStore.getEditorData().html == '' "/></div>
          </div>
        </div>
      </div>
      
      
      <a ref="outputPngFile" id="pName" class="hide"  v-bind:pName="pName" :download="pN+'.png'"></a>
      <div ref="outputPngCanvas" class="hide"></div>
      <a ref="outputTxtFile" class="hide" download="schema.txt"></a>
      <input ref="erInputFile" type="file" class="hide" accept=".qt" tabindex="-1" @change="readFile($event.target.files[0])" />
      <a ref="erOutputFile"  id="pName" class="hide" :download="pN+'.qt'"></a>
      <a ref="outputNotesFile" class="hide" :download="pN+'.txt'"></a>
    </div>
  </div>
</template>

<script>
import { computed, ref, inject } from 'vue';
import ToolboxButton from './ToolboxButton';
import RATableSchema from './RATableSchema';
import RAInfoSchema from './RAInfoSchema';
import Attribute from '../lib/attribute';
import { getContext } from '../lib/editor';
import analytics from '../services/analytics';
import analyticsEvents from '../services/analytics/events';
import { mainStore } from '../store/main';
import RAInfoTemp from './RAInfoTemp';
import PiPredicate from './PiPredicate';
import RAPredicates from './RAPredicates'

export default {
  name: 'ERModelEditorToolbox',
  components: {
    ToolboxButton,
    RATableSchema,
    RAInfoSchema,
    RAInfoTemp,
    PiPredicate,
    RAPredicates,
    },
    mounted(){
      //this.$root.$listen("input", (e) => {console.log(e);})
    },

    props: ['pName'],

  directives: {
    focusselect: {
      mounted(el) {
        el.focus();
        el.select();
      }
    }
  },
    
  created() {
        window.addEventListener('keydown', this.onKeyDown);        
        window.addEventListener('keypress', this.onKeyPress);
        window.addEventListener('click', this.onClickOver);
        window.addEventListener('dblclick', this.onDoubleClick)
    },
   
  methods:{
    listen(e){
      console.log('event' + e);
    },
    onClose() {
      this.$emit("dialog-closed");
      this.onClickOver(null);
    },

    renameItem(e) {
      //e.preventDefault();   
      this.store.renameItem(e.target.value);
      //this.showRenamingInput=true;
      // console.log(this.showRenamingInput);
      e.target.value = this.store.getRenamingValue();     
    },

    onkeyPress(){
      //
        },
    onKeyDown(e) {   
      if(!this.showRenamingInput){   
        if( e.ctrlKey && e.key == 's' /* s */ && !this.empty ){
            e.preventDefault();
            this.saveToFile();
          }
        if( e.ctrlKey && e.key == 'e' /* s */ && this.canExport ){
            e.preventDefault();
            this.saveAsPng();
          }
        if( e.ctrlKey && e.key == 'n' /* n */ ){
            e.preventDefault();
          }
        if(this.getSelectedItem()!=null){
          if(this.getSelectedItem().__type =='Entity' && !this.showSchema){
            if(e.shiftKey  && e.key == 'S' /* s */ ){
              e.preventDefault();
              this.store.toggleMenu('schema');   
            }  
            if(e.shiftKey && e.key == 'I' /* i */  ){
              e.preventDefault();
              this.store.toggleMenu('info');
            }          
            if(e.shiftKey && e.key == 'D' /* d */ ){
              e.preventDefault();
              this.store.selectTool('copy');
            }
          }
          if(this.getSelectedItem().__type =='Relationship'){
            //console.log( ctx.diagram.selectedItem.getAllowedFunctionalities().entityParticipation.ok);
            if(e.shiftKey && e.key == 'C' /* c */ && this.getSelectedItem().getAllowedFunctionalities().entityParticipation.ok) {
              e.preventDefault();
              this.store.selectTool('connect');
            }
            if(e.shiftKey && e.key == 'P' /* p */ ){
              e.preventDefault();
              this.store.toggleMenu('renaming');
            }
          }
          if(this.getSelectedItem().__type =='Participation'){
            if(e.shiftKey && e.key == 'N' /* n */ ) {
              e.preventDefault();
              this.store.toggleMenu('renaming');
            }
          }
        }
      }
    },

    onClickOver() {
     //
    /* console.log(this.showRenamingInput);
     if(!this.showRenamingInput!=false && this.allowRename!=false && this.selectedItem!=null && this.selectedItem.isEntity() && this.handleAdd() )
      */
     //this.store.toggleMenu('renaming');
    },
    onDoubleClick(){
      if(this.selectedItem!=null && this.selectedItem.__type =='Participation' && this.selectedItem.getComment()){
        console.log(this.selectedItem.getComment());
        this.store.toggleMenu('commentInfo');
      }
      if( this.disabled!=true && this.selectedItem!=null && this.selectedItem.__type =='Entity' ){//&& this.selectedItem.getName().match(/[a-zA-Z_]/g)=='T,a,b,l,e'){
        this.store.toggleMenu('renaming');        
      }
    }
  },
  setup() {
    let currentURL = null;
    const outputPngFile = ref(null);
    const outputPngCanvas = ref(null);
    const outputTxtFile = ref(null);
    const erOutputFile = ref(null);
    const empty = mainStore.$empty();
    const erDesignStore = mainStore.erDesign;
    const state = erDesignStore.getState();
    const canUndo = mainStore.$canUndo();
    const canRedo = mainStore.$canRedo();
    const selectedItem = erDesignStore.$selectedItem();
    const selectedTool = erDesignStore.$selectedTool();
    const showRenamingInput = erDesignStore.$renamingInput();
    const showRenamingRoleInput = erDesignStore.$renamingRoleInput();
    const showCardinalityMenu = erDesignStore.$cardinalityMenu();
    const showGeneralizationTypeMenu = erDesignStore.$generalizationTypeMenu();
    const showExportMenu = erDesignStore.$exportMenu();
    const canTranslate = erDesignStore.$canTranslate();
    const canExport = erDesignStore.$canExport();
    const showCanClickResult= erDesignStore.$getCanClickResult();
    const showJoinMenu= erDesignStore.$joinMenu();
    const showOthersMenu=erDesignStore.$othersMenu();
    const showSchema=erDesignStore.$showSchema();
    const showInfo=erDesignStore.$showInfo();
    const showTemp=erDesignStore.$showTemp();
    const showArch=erDesignStore.$showArch();
    const showCommentInput=erDesignStore.$commentInput();
    const showCommentInfo=erDesignStore.$commentInfo();
    const showRenamingFast=erDesignStore.$renamingFast();
    const supported = computed(() => selectedItem.value.getSupportedFunctionalities());    
    const allowed = computed(() => selectedItem.value.getAllowedFunctionalities());
    const isAttribute = computed(() => selectedItem.value instanceof Attribute);
    const selectedItemCardinality = computed(() => selectedItem.value.getSupportedFunctionalities().cardinality && selectedItem.value.getCardinality());
    const selectedItemGeneralizationType = computed(() => selectedItem.value.getSupportedFunctionalities().generalizationType && selectedItem.value.getType());
    let disabled = false;    
    const pN = inject('pN');
    const outputNotesFile = ref(null);

    function saveToFile() {
      //console.log('SAVE chiamata');
      const data = mainStore.serializeState();
      if(currentURL)
        window.URL.revokeObjectURL(currentURL);
      currentURL = window.URL.createObjectURL(new Blob([data]));
      erOutputFile.value.href = currentURL;
      erOutputFile.value.click();
      analytics.event(analyticsEvents.APP_SAVE_DESIGN);
    }
    function addComment(e) {  
    const item = this.getSelectedItem();
    if(item.getSupportedFunctionalities().comment)
      item.setComment(e.target.value);
    }
    function renameRole(e) {
      erDesignStore.setRole(e.target.value);
      e.target.value = erDesignStore.getRenamingValue();
    }
    function translate() {
      if(state.model.needsRestructuring())
        mainStore.moveToRestructuring();
      else
        mainStore.moveToTranslation();
    }
    async function saveAsPng() {
      erDesignStore.toggleMenu();
      if(currentURL)
        window.URL.revokeObjectURL(currentURL);
      const context = getContext('erDesign');
      currentURL = window.URL.createObjectURL(new Blob([await context.exportPng(outputPngCanvas.value)]));
      outputPngFile.value.href = currentURL;
      outputPngFile.value.click();
      //analytics.event(analyticsEvents.ER_SCHEMA_EXPORT_PNG);
    }
    function saveAsTxt() {
      console.log(erDesignStore);
      erDesignStore.toggleMenu();
      if(currentURL)
        window.URL.revokeObjectURL(currentURL);
      currentURL = window.URL.createObjectURL(new Blob([erDesignStore.getCode()]));
      outputTxtFile.value.href = currentURL;
      outputTxtFile.value.click();
      analytics.event(analyticsEvents.ER_SCHEMA_EXPORT_TXT);
    }
    function saveAsNotes() {
      if(mainStore.getEditorData()){
      erDesignStore.toggleMenu();
      if(currentURL)
        window.URL.revokeObjectURL(currentURL);
       //replace(/(<([^>]+)>)/ig, '');      
      currentURL = window.URL.createObjectURL(new Blob([mainStore.getEditorData().replace(/(<([^>]+)>)/ig, '')]));
      outputNotesFile.value.href = currentURL;
      outputNotesFile.value.click();
      }
    }
    function onRenameKeydown(e) {
      if(e.which == 13 || e.which == 27)
        erDesignStore.toggleMenu();
    }
    function getSelectedItem(){
      return erDesignStore.getSelectedItem();
    }
    function handleClick(){
      //console.log(this.getSelectedItem().getTypeOfInPut());    
      if(this.getSelectedItem().getObjName()){
        this.getSelectedItem().setToModify(true);
        // console.log(this.getSelectedItem().getToModify());
      }      
    }   
    function allowDelete(){
      //console.log(this.getSelectedItem().getParticipationCount() && ==1);
      if(this.getSelectedItem().isRelationship() && this.getSelectedItem().getTypeOfInPut()=='result')
        return true;
      if(this.getSelectedItem().isRelationship() && this.getSelectedItem().getParticipationCount()==1)
        return false;
      if(this.getSelectedItem().isEntity() && this.getSelectedItem().getParticipations()!='')
        return false;
      return true;
    }
    function allowSchema(){
      if(this.getSelectedItem().getEntity() && this.getSelectedItem().getEntity().isEntity())
        return false;
      return true;
    }    
    function allowPredicate(){
      //console.log(this.getSelectedItem());
      if(!this.getSelectedItem().getTempAttrs()){
        return false;
      } 
      return true;
    }
    return {
      store: erDesignStore,
      mainStore,
      canUndo,
      canRedo,
      showRenamingInput,
      showRenamingRoleInput,
      showCardinalityMenu,
      showGeneralizationTypeMenu,
      showExportMenu,
      outputPngFile,
      outputPngCanvas,
      outputTxtFile,
      selectedItem,
      selectedTool,
      canTranslate,
      canExport,
      supported,
      allowed,
      isAttribute,
      selectedItemCardinality,
      selectedItemGeneralizationType,
      addComment,
      renameRole,
      translate,
      saveAsPng,
      saveAsTxt,
      onRenameKeydown,
      showCanClickResult,
      showJoinMenu,
      showOthersMenu,
      showSchema,
      showInfo,
      showTemp,
      showArch,
      showCommentInput,
      showCommentInfo,
      RAInfoTemp,
      PiPredicate,
      RAPredicates,
      getSelectedItem,
      handleClick,
      allowDelete,
      allowSchema,
      allowPredicate,
      showRenamingFast,
      erOutputFile,
      empty,
      saveToFile,
      disabled,
      allowRename: true,
      pN,
      saveAsNotes,
      outputNotesFile,
    };
  }, 
};
</script>

<style>
.my-place{
  color: black;
}

</style>