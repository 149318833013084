<template>
  <div class="editor" tabindex="1" @keydown="onEvent($event)">
    <div class="diagram-editor-container" ref="erCanvas" @mousedown="onEvent($event)" @mouseup="onEvent($event)" @click="onEvent($event)"
      @mousemove="onEvent($event)" @mouseleave="onEvent($event)" @contextmenu="onEvent($event)" @wheel="onEvent($event)"
      @mousewheel="onEvent($event)" @DOMMouseScroll="onEvent($event)" @touchstart="onEvent($event)" @touchmove="onEvent($event)"
      @touchend="onEvent($event)" @touchcancel="onEvent($event)"></div>
    <slot></slot>
  </div>
</template>

<script>
import { onBeforeUnmount, onMounted, ref, watch } from 'vue';

import { getContext } from '../lib/editor';

export default {
  name: 'DiagramEditor',
  props: ['contextName', 'restructuring', 'translating', 'store'],
  setup(props) {
    const erCanvas = ref(null);
    const context = getContext(props.contextName);
    
    const state = props.store.getState();
    const sceneResetToken = props.store.$sceneResetToken?.();
    const selectedItem = props.store.$selectedItem?.();
    const selectedTool = props.store.$selectedTool?.();

    function setCanvas() {
      context.canvasUpdated(erCanvas.value);
    }
    function onEvent(e) {
      context.onEvent(e);
    }

    onMounted(() => {
      setCanvas();
      context.modelUpdated(state.model, true);
      context.centerScene();
      window.addEventListener('resize', setCanvas);
    });
    onBeforeUnmount(() => {
      window.removeEventListener('resize', setCanvas);
    });

    if(sceneResetToken)
      watch(sceneResetToken, () => context.resetScene());
    if(selectedItem)
      watch(selectedItem, item => context.selectedItemUpdated(item));
    if(selectedTool)
      watch(selectedTool, tool => context.selectedToolUpdated(tool));
    
    watch(() => state.model, model => context.modelUpdated(model), { deep: true });
    
    return {
      erCanvas,
      onEvent
    };
  }
};
</script>

<style scoped>
.editor {
  flex: 1;
  display: flex;
  flex-direction: column;
  outline: none;
}
.diagram-editor-container {
  flex: 1;
}
</style>