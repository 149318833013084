import colors from './colors';
import DiagramGroup from './diagramGroup';
import DiagramText from './diagramText';

// quello con il predicato che funziona
const drawRelationshipText = () => {
  let _text = new DiagramText();
  _text.family = 'Commissioner';
  _text.fill = colors.BLACK;
  return _text;
};

const drawRelationshipText2 = () => {
  let _text = new DiagramText();
  _text.family = 'Commissioner';
  _text.fill = colors.BLACK;
  return _text;
};

const drawRelationshipText3 = () => {
  let _text = new DiagramText();
  _text.family = 'Commissioner';
  _text.fill = colors.BLACK;
  return _text;
};

const drawRelRectangle = context => {
  let _rect = context.two.makeRectangle(0, 0, 10, 60);
  _rect.fill = colors.TRANSPARENT;
  _rect.linewidth=1;
 // _rect.stroke= colors.BLACK;
  _rect.noStroke();
  return _rect;
};
const drawWarnLine = context => {
  let _rect = context.two.makePath();
  _rect.noStroke();
  return _rect;
};
class RelationshipGroup extends DiagramGroup {
  constructor(id, context) {
    super(id, context);
    this.erText = drawRelationshipText();
    this.erText.position.set(0, 0);
    this.erText2 = drawRelationshipText2();
    this.erText.position.set(10,18);
    this.erText3 = drawRelationshipText3();
    this.erText3.position.set(0,-19); //anti semi joinline 
    this.predicate1 = drawRelationshipText();
    this.predicate1.position.set(15,35);
    this.predicate2 = drawRelationshipText();
    this.predicate2.position.set(15,52);
    this.predicate3 = drawRelationshipText();
    this.predicate3.position.set(15,69);
    this.p1=0;
    this.p2=0;
    this.p3=0;
    
    this.typeOfIn;
    this.context.two.update();
    this.erRect=drawRelRectangle(this.context);
    this.erRect.position.set(0,0);
    this.globalNum = drawRelationshipText();
    this.globalNum.position.set(0, -20);
    this.nameInfo;

    //this.erText.position.set(0,25);
    this.erTextGroup=this.context.two.makeGroup(this.erText3, this.erText2, this.erText, this.predicate1, this.predicate2,this.predicate3, this.globalNum);
    this.erTextGroup.position.set(this.context.two.width,this.context.two.height);
    this.erAttrs = this.context.two.makeGroup();
    this.erWarn = drawWarnLine(this.context);
    this.add(this.erAttrs, this.erText, this.erText2, this.erText3, this.typeOfIn, this.erTextGroup, this.erWarn, this.erRect, this.predicate1, this.predicate2,this.predicate3, this.p1, this.p2, this.p3, this.globalNum, this.nameInfo);
  }
  getAttrs() {
    return this.erAttrs;
  }
  getShape() {
    //console.log('x:'+ this.translation.x, 'y'+ this.translation.y)
    return { x: this.translation.x, y: this.translation.y, width: this.erRect.width, height: this.erRect.height };
  }

  getTypeOfInPut(){
    return this.typeOfIn;
  }
  getP1(){
    if(this.p1!=0)
      return true;
    else return false;
  }
  getP2(){
    if(this.p2!=0)
      return true;
    else return false;
  }
  getP3(){
    if(this.p3!=0)
      return true;
    else return false;
  }
  setName(name, typeOfIn) {
    /*let p1=0;
    let p2=0;
    let p3=0;*/
    this.predicate1.value='';
    this.predicate2.value='';
    this.predicate3.value='';

    switch (typeOfIn){
      case 'unaryInput': 
      typeOfIn="s";
        break;
      case 'unaryInputPi':
        typeOfIn="s";
        break;
      case 'join':
        typeOfIn="s";
        break;
      case 'naturalJoin':
        typeOfIn ="natural"
        break;
    } 

    for(let i=0; i<name.length; i++){
      if(name[i]==(' ') && i>20){
        //console.log('primo spazio utile');
        this.p1=i;
      break;
      }
      //aggiungi qui
    }
    for(let i=20; i<name.length; i++){
      if(name[i]==(' ') && i>50){
      //console.log('secondo spazio utile');
      this.p2=i;
      break;
      } 
     //aggiungi qui
    }

    for(let i=40; i<name.length; i++){
      if(name[i]==(' ') && i>80){
      //console.log('terzo spazio utile');
      this.p3=i;
      break;
      }
    //aggiungi qui
    }
  /*console.log('stringa1 :'+ name.substring(0,p1));
  console.log('stringa2 :'+ name.substring(p1,p2));
  console.log('stringa3 :'+ name.substring(p2,p3));
  //console.log('stringa4 :'+ name.substring(p3,50));*/

    this.erText.value = name;
    this.erText.size = 15; //grandezza predicato

    if(this.p1!=0){
      this.erText.value = name.substring(0,this.p1);
      this.predicate1.value = name.substring(this.p1);
      //this.erRect.position.set(0,20);
      //this.erRect.height =65;
      //this.translation.y=65;
      //console.log('y nuova' + this.translation.y);
    }
    if(this.p2!=0){
      this.erText.value = name.substring(0,this.p1);
      this.predicate1.value = name.substring(this.p1,this.p2);
      this.predicate2.value=name.substring(this.p2);
      //this.erRect.position.set(0,30);
      //this.erRect.height =95;
      //this.y=95;      
    }
    if(this.p3!=0){
      this.erText.value = name.substring(0,this.p1);
      this.predicate1.value = name.substring(this.p1,this.p2);
      this.predicate2.value=name.substring(this.p2,this.p3);
      this.predicate3.value=name.substring(this.p3);
      //this.erRect.position.set(0,40);
      //this.erRect.height =135;
      //this.y=135;
    }
    /*this.predicate1.value = name.substring(p1,p2) : this.predicate1.value = name.substring(p1);
    this.predicate1.size = name.substring(p1,p2).length > 10 ? 15: 15  : ''; //grandezza predicato
    this.predicate2.value = name.substring(p2,p3) : this.predicate2.value=name.substring(p2);
    this.predicate2.size = name.substring(p2,p3).length > 10 ? 15: 15 : ''; //grandezza predicato
    this.predicate3.value = name.substring(p3) : this.predicate3.value=name.substring(p3);
    this.predicate3.size = name.substring(p3).length > 10 ? 15: 15 : ''; //grandezza predicato
    */
    this.predicate1.size = 15;
    this.predicate2.size = 15;
    this.predicate3.size = 15;
    this.nameInfo=name;
    this.globalNum.value=' ';
    this.globalNum.size = 10;
    this.erText3.value=' ';
    this.erText3.size = 20;
    this.erText2.value= typeOfIn;
    this.erText2.size = 30; //operator
    
    const modelScene = this.context.diagram.scene.$model;
    //const slope = 0.694 * Math.pow(0.9365, Math.max(3, name.length) / 3);
    const textWidth = this.erText.getBoundingClientRect().width / modelScene.scale;
    //const paddedTextWidth = textWidth +20;
    this.erRect.width = Math.max(50, textWidth);
    //this.erRectHeight = paddedTextWidth ;
    this.erWarn.width = textWidth;  
  }
  setSelected(selected) {
    this.erText.weight = selected ? 600 : 400;
    this.predicate1.weight = selected ? 600 : 400;
    this.predicate2.weight = selected ? 600 : 400;
    this.predicate3.weight = selected ? 600 : 400; 
    this.erText2.weight = selected ? 600 : 400;
    this.erText3.weight = selected ? 600 : 400;
    this.erWarn.height = selected ? 4 : 2;
  }
  setBar(){
    this.erText3.value='__';
  }
  setGlobal(){
    //this.globalNum.value='('+this.nameInfo+')';
    this.globalNum.value='';
    this.erText.value='';
  }
  setWarningsVisible(visible, errors) {
    this.erWarn.fill = errors ? colors.RED : colors.YELLOW;
    this.erWarn.opacity = visible ? 1 : 0;
  }  
}

export const drawRelationship = (relationship, context) => {
  const scene = context.diagram.scene;
  let _relationship = context.diagram.$(relationship.getId());
  
  const warningsAllowed = !context.diagram.transparent;
  const relationshipErrors = relationship.getErrors().filter(e => !e.scope || e.scope == context.diagram.step);
  const relationshipWarnings = relationship.getWarnings().filter(w => !w.scope || w.scope == context.diagram.step);
  
  //const translating = context.diagram.step == 'erTranslation';

  if(!_relationship) {
    _relationship = new RelationshipGroup(relationship.getId(), context);
    context.diagram.register(relationship.getId(), _relationship);
    scene.$modelInner.add(_relationship);
  }

  _relationship.setName(relationship.getName(), relationship.getTypeToDraw());
  _relationship.setPosition(relationship.getX(), relationship.getY());
  _relationship.setSelected(context.diagram.selectedItem?.getId() == relationship.getId());
  _relationship.setWarningsVisible(warningsAllowed && (relationshipErrors.length || relationshipWarnings.length), relationshipErrors.length);
  if(relationship.getTypeOfInPut()=='asemi-join'){
    _relationship.setBar();
  }
  let r=relationship.getTypeOfInPut();
  if(r=='naturalJoin' || r=='difference' || r=='division' || r=='intersection' || r=='union'){
  _relationship.setGlobal();
  }
};