export const translateOneToOneTypeARelationship = function(model, relationshipId) {
  const relationship = model.getItemById(relationshipId);
  const oneoneParticipation = relationship.getParticipations().find(p => p.getCardinality() == '1_1');
  const zerooneParticipation = relationship.getParticipations().find(p => p.getCardinality() == '0_1');
  const tableA = model.getItemById(oneoneParticipation.getTable().getId());
  const tableB = model.getItemById(zerooneParticipation.getTable().getId());
  let columnIndex = tableA.getColumns().length;
  for(let column of tableB.getColumns().filter(c => c.isIdentifier())) {
    const columnName = `${column.getTable().getName()}_${column.getName()}${zerooneParticipation.getRole() ? '_' + zerooneParticipation.getRole() : ''}`;
    const genColumn = model.addColumn(columnName, tableA.getId(), columnIndex++, false, false);
    model.addForeignKey(genColumn.getId(), column.getId());
  }
  for(let attribute of relationship.getAttributes())
    model.addColumn(attribute.getName(), tableA.getId(), columnIndex++, false, attribute.getMinCardinality() == '0');
  model.deleteItem(relationship.getId());
};

export const translateOneToOneTypeBRelationship = function(model, relationshipId, first) {
  const relationship = model.getItemById(relationshipId);
  const participationA = relationship.getParticipations()[first ? 0 : 1];
  const participationB = relationship.getParticipations()[first ? 1 : 0];
  const tableA = model.getItemById(participationA.getTable().getId());
  const tableB = model.getItemById(participationB.getTable().getId());
  let columnIndex = tableA.getColumns().length;
  for(let column of tableB.getColumns().filter(c => c.isIdentifier())) {
    const columnName = `${column.getTable().getName()}_${column.getName()}${participationB.getRole() ? '_' + participationB.getRole() : ''}`;
    const genColumn = model.addColumn(columnName, tableA.getId(), columnIndex++, false, false);
    model.addForeignKey(genColumn.getId(), column.getId());
  }
  for(let attribute of relationship.getAttributes())
    model.addColumn(attribute.getName(), tableA.getId(), columnIndex++, false, attribute.getMinCardinality() == '0');
  model.deleteItem(relationship.getId());
};

export const translateOneToOneTypeCRelationship = function(model, relationshipId, createTable, first) {
  const relationship = model.getItemById(relationshipId);
  const participationA = relationship.getParticipations()[first ? 0 : 1];
  const participationB = relationship.getParticipations()[first ? 1 : 0];
  const tableA = model.getItemById(participationA.getTable().getId());
  const tableB = model.getItemById(participationB.getTable().getId());
  let columnIndex;
  if(createTable) {
    columnIndex = 0;
    const table = model.addTable(relationship.getName(), relationship.getX(), relationship.getY());
    for(let column of tableA.getColumns().filter(c => c.isIdentifier())) {
      const columnName = `${column.getTable().getName()}_${column.getName()}${participationA.getRole() ? '_' + participationA.getRole() : ''}`;
      const genColumn = model.addColumn(columnName, table.getId(), columnIndex++, true, false);
      model.addForeignKey(genColumn.getId(), column.getId());
    }
    for(let column of tableB.getColumns().filter(c => c.isIdentifier())) {
      const columnName = `${column.getTable().getName()}_${column.getName()}${participationB.getRole() ? '_' + participationB.getRole() : ''}`;
      const genColumn = model.addColumn(columnName, table.getId(), columnIndex++, false, false);
      model.addForeignKey(genColumn.getId(), column.getId());
    }
    for(let attribute of relationship.getAttributes())
      model.addColumn(attribute.getName(), table.getId(), columnIndex++, false, attribute.getMinCardinality() == '0');
  } else {
    columnIndex = tableA.getColumns().length;
    for(let column of tableB.getColumns().filter(c => c.isIdentifier())) {
      const columnName = `${column.getTable().getName()}_${column.getName()}${participationB.getRole() ? '_' + participationB.getRole() : ''}`;
      const genColumn = model.addColumn(columnName, tableA.getId(), columnIndex++, false, true);
      model.addForeignKey(genColumn.getId(), column.getId());
    }
    for(let attribute of relationship.getAttributes())
      model.addColumn(attribute.getName(), tableA.getId(), columnIndex++, false, true);
  }
  model.deleteItem(relationship.getId());
};