import { computed } from 'vue';

import { Store } from './store';
import Model from '../lib/model';
import Entity from '../lib/entity';
import Relationship from '../lib/relationship';
import analytics from '../services/analytics';
import analyticsEvents from '../services/analytics/events';

export default class ERTranslationStore extends Store {
  constructor(main) {
    super();
    this.mainStore = main;
  }
  __replace(state) {
    this.state.edited = state.edited;
    this.state.sceneResetToken = new Date().getTime();
    
    this.state.selectedItemId = null;
    this.state.oldRenamingValue = '';
    this.state.renamingValue = '';
    this.state.showRenamingInput = false;
    this.state.showOneToManyTypeBRelationshipMenu = false;
    this.state.showOneToOneTypeBRelationshipMenu = false;
    this.state.showOneToOneTypeCRelationshipMenu = false;
    
    this.state.model = Model.fromObject(state.model || {});
  }
  data() {
    return {
      model: new Model(),
      edited: false,
      sceneResetToken: null,
      selectedItemId: null,
      oldRenamingValue: '',
      renamingValue: '',
      showRenamingInput: false,
      showOneToManyTypeBRelationshipMenu: false,
      showOneToOneTypeBRelationshipMenu: false,
      showOneToOneTypeCRelationshipMenu: false
    };
  }
  _resetMenus() {
    let updated = false;
    if(this.state.showRenamingInput && this.state.oldRenamingValue != this.state.renamingValue)
      updated = true;

    this.state.showRenamingInput = false;
    this.state.showOneToManyTypeBRelationshipMenu = false;
    this.state.showOneToOneTypeBRelationshipMenu = false;
    this.state.showOneToOneTypeCRelationshipMenu = false;

    if(updated) {
      this._itemsUpdated();
      const oldName = this.state.oldRenamingValue, newName = this.state.renamingValue;
      const itemType = this.state.model.getItemById(this.state.selectedItemId).constructor.name;
      analytics.event(analyticsEvents.TRANSL_ITEM_RENAME, { itemType, oldName, newName });
    }
  }
  _itemsUpdated(pushState = true) {
    this.state.edited = true;
    if(pushState)
      this.mainStore.pushState();
  }
  $sceneResetToken() {
    return computed(() => this.state.sceneResetToken);
  }
  $selectedItem() {
    return computed(() => this.getSelectedItem());
  }
  $renamingInput() {
    return computed(() => this.state.showRenamingInput);
  }
  $oneToManyTypeBRelationshipMenu() {
    return computed(() => this.state.showOneToManyTypeBRelationshipMenu);
  }
  $oneToOneTypeBRelationshipMenu() {
    return computed(() => this.state.showOneToOneTypeBRelationshipMenu);
  }
  $oneToOneTypeCRelationshipMenu() {
    return computed(() => this.state.showOneToOneTypeCRelationshipMenu);
  }
  $selectedItemWarnings() {
    return computed(() => {
      const item = this.getSelectedItem();
      if(item && item.getWarnings)
        return item.getWarnings().filter(w => !w.scope || w.scope == 'erTranslation');
      return null;
    });
  }
  $selectedItemErrors() {
    return computed(() => {
      const item = this.getSelectedItem();
      if(item && item.getErrors)
        return item.getErrors().filter(e => !e.scope || e.scope == 'erTranslation');
      return null;
    });
  }
  $modelContainsEntities() {
    return computed(() => this.state.model.getItems().some(i => i instanceof Entity));
  }
  $canFinalize() {
    return computed(() => !this.state.model.needsTranslation());
  }
  getSelectedItem() {
    return this.state.selectedItemId ? this.state.model.getItemById(this.state.selectedItemId) : null;
  }
  getRenamingValue() {
    return this.state.renamingValue;
  }
  selectItem(id) {
    this._resetMenus();
    this.state.selectedItemId = id;
  }
  moveItem(dx, dy) {
    this._resetMenus();
    const item = this.getSelectedItem();
    if(item.getSupportedFunctionalities().moving)
      item.move(dx, dy);
  }
  finishedMovingItem() {
    const item = this.getSelectedItem();
    if(item.getSupportedFunctionalities().moving)
      this._itemsUpdated();
  }
  renameItem(name) {
    const item = this.getSelectedItem();
    if(item.getSupportedFunctionalities().renaming)
      this.state.renamingValue = item.setName(name);
    this._itemsUpdated(false);
  }
  translateItem() {
    const item = this.getSelectedItem();
    if(item instanceof Entity)
      this.translateEntity();
    else if(item instanceof Relationship)
      this.translateRelationship();
  }
  translateEntity() {
    const entity = this.getSelectedItem();
    this.state.model.translateEntity(entity.getId());
    if(entity.hasKey())
      analytics.event(analyticsEvents.TRANSL_INTID_ENTITY);
    else
      analytics.event(analyticsEvents.TRANSL_EXTID_ENTITY);
    
    this.state.selectedItemId = null;
    this._itemsUpdated();
  }
  translateRelationship() {
    let translated = false;
    const relationship = this.getSelectedItem();
    const cardinalities = relationship.getParticipations().map(p => p.getCardinality());
    if(relationship.isTernary() || relationship.isManyToMany()) {
      this.state.model.translateManyToManyRelationship(relationship.getId());
      translated = true;
      if(relationship.isTernary())
        analytics.event(analyticsEvents.TRANSL_TERNARY_RELATIONSHIP, { cardinalities });
      else
        analytics.event(analyticsEvents.TRANSL_MANYTOMANY_RELATIONSHIP, { cardinalities });
    } else if(relationship.isOneToMany()) {
      if(cardinalities.some(c => c == '1_1')) {
        this.state.model.translateOneToManyTypeARelationship(relationship.getId());
        translated = true;
        analytics.event(analyticsEvents.TRANSL_ONETOMANY_RELATIONSHIP, { cardinalities });
      } else
        this.toggleMenu('oneToManyTypeARelationship');
    } else if(relationship.isOneToOne()) {
      if(cardinalities.some(c => c == '0_1') && cardinalities.some(c => c == '1_1')) {
        this.state.model.translateOneToOneTypeARelationship(relationship.getId());
        translated = true;
        analytics.event(analyticsEvents.TRANSL_ONETOONE_RELATIONSHIP, { cardinalities });
      } else if(cardinalities.every(c => c == '1_1'))
        this.toggleMenu('oneToOneTypeBRelationship');
      else if(cardinalities.every(c => c == '0_1'))
        this.toggleMenu('oneToOneTypeCRelationship');
    }

    if(translated) {
      this.state.selectedItemId = null;
      this._itemsUpdated();
    }
  }
  translateOneToManyTypeBRelationship(strategy) {
    const relationshipId = this.state.selectedItemId;
    const cardinalities = this.getSelectedItem().getParticipations().map(p => p.getCardinality());
    if(strategy == 'into_01')
      this.state.model.translateOneToManyTypeBRelationship(relationshipId, false);
    else if(strategy == 'table')
      this.state.model.translateOneToManyTypeBRelationship(relationshipId, true);
      
    analytics.event(analyticsEvents.TRANSL_ONETOMANY_RELATIONSHIP, { cardinalities, strategy });
    this.state.selectedItemId = null;
    this._itemsUpdated();
  }
  translateOneToOneTypeBRelationship(strategy) {
    const relationshipId = this.state.selectedItemId;
    const cardinalities = this.getSelectedItem().getParticipations().map(p => p.getCardinality());
    if(strategy == 'into_first')
      this.state.model.translateOneToOneTypeBRelationship(relationshipId, true);
    else if(strategy == 'into_second')
      this.state.model.translateOneToOneTypeBRelationship(relationshipId, false);

    analytics.event(analyticsEvents.TRANSL_ONETOONE_RELATIONSHIP, { cardinalities, strategy });
    this.state.selectedItemId = null;
    this._itemsUpdated();
  }
  translateOneToOneTypeCRelationship(strategy) {
    const relationshipId = this.state.selectedItemId;
    const cardinalities = this.getSelectedItem().getParticipations().map(p => p.getCardinality());
    if(strategy == 'into_first')
      this.state.model.translateOneToOneTypeCRelationship(relationshipId, false, true);
    else if(strategy == 'into_second')
      this.state.model.translateOneToOneTypeCRelationship(relationshipId, false, false);
    else if(strategy == 'table_first')
      this.state.model.translateOneToOneTypeCRelationship(relationshipId, true, true);
    else if(strategy == 'table_second')
      this.state.model.translateOneToOneTypeCRelationship(relationshipId, true, false);

    analytics.event(analyticsEvents.TRANSL_ONETOONE_RELATIONSHIP, { cardinalities, strategy });
    this.state.selectedItemId = null;
    this._itemsUpdated();
  }
  toggleMenu(menu) {
    if(menu == 'renaming') {
      const showRenaming = this.state.showRenamingInput;
      this._resetMenus();
      this.state.showRenamingInput = !showRenaming;
      this.state.oldRenamingValue = this.getSelectedItem().getName();
      this.state.renamingValue = this.getSelectedItem().getName();
    } else if(menu == 'oneToManyTypeARelationship') {
      const showOneToManyTypeBRelationship = this.state.showOneToManyTypeBRelationshipMenu;
      this._resetMenus();
      this.state.showOneToManyTypeBRelationshipMenu = !showOneToManyTypeBRelationship;
    } else if(menu == 'oneToOneTypeBRelationship') {
      const showOneToOneTypeBRelationship = this.state.showOneToOneTypeBRelationshipMenu;
      this._resetMenus();
      this.state.showOneToOneTypeBRelationshipMenu = !showOneToOneTypeBRelationship;
    } else if(menu == 'oneToOneTypeCRelationship') {
      const showOneToOneTypeCRelationship = this.state.showOneToOneTypeCRelationshipMenu;
      this._resetMenus();
      this.state.showOneToOneTypeCRelationshipMenu = !showOneToOneTypeCRelationship;
    } else
      this._resetMenus();
  }
}