import { drawModel } from './model';
import { drawOverlays } from './overlays';

export const resetScene = context => {
  context.diagram.scene = {};
  context.diagram.svgIds = {};
  context.diagram.$ = function(id) {
    return this.scene[`$item_${id}`];
  },
  context.diagram.register = function(id, _item) {
    this.svgIds[_item.id] = id;
    this.scene[`$item_${id}`] = _item;
  }
  if(context.two) {
    context.two.clear();
    context.two.update();
  }
};

export const centerScene = context => {
  const modelScene = context.diagram.scene.$model;
  const scale = modelScene.scale, offset = modelScene.translation;
  const rect = modelScene.getBoundingClientRect();
  const offsetX = context.canvas.width / 2 - (rect.left - offset.x + rect.width / 2) / scale;
  const offsetY = context.canvas.height / 2 - (rect.top - offset.y + rect.height / 2) / scale;
  context.transform.offsetX = isNaN(offsetX) ? 0 : offsetX;
  context.transform.offsetY = isNaN(offsetY) ? 0 : offsetY;
  
  draw(context);
};

export const draw = (context, clear = false) => {
  if(clear)
    resetScene(context);

  if(!context.two || !context.diagram.model)
    return;

  if(clear) {
    context.two.makeGroup(
      drawModel(context),
      drawOverlays(context)
    );
  } else {
    drawModel(context);
    drawOverlays(context);
  }

  context.two.update();
};