/**
 * Quadrant enum
 */
export const QUADRANT = {
  RIGHT: 0,
  TOP: 1,
  LEFT: 2,
  BOTTOM: 3
};

/**
 * Convert degrees to radians
 */
export const degrees = radians => {
  return radians / Math.PI * 180;
};
/**
 * Convert degrees to radians
 */
export const radians = degrees => {
  return degrees / 180 * Math.PI;
};
/**
 * Convert from [-pi, pi] to [0, 2pi]
 */
export const normalizeAngle = radians => {
  return (2 * Math.PI + radians) % (2 * Math.PI);
};
/**
 * Convert quadrant to angle
 */
export const quadrantToAngle = quadrant => {
  return quadrant * Math.PI / 2;
};
/**
 * Distance between two points with absolute coordinates (@param x1, @param y1) and (@param x2, @param y2)
 */
export const distance = (x1, y1, x2, y2) => {
  return Math.sqrt((x1 - x2) * (x1 - x2) + (y1 - y2) * (y1 - y2));
};
/**
 * Distance between a point with absolute coordinates (@param x, @param y) and the origin (0, 0)
 */
export const distanceFromOrigin = (x, y) => {
  return distance(x, y, 0, 0);
};
/**
 * Point on the line connecting A (@param x1, @param y1) and B (@param x2, @param y2) at distance @param dist from A
 */
export const pointAtDistance = (x1, y1, x2, y2, dist) => {
  const a = Math.atan2(y2 - y1, x2 - x1);
  return [x1 + dist * Math.cos(a), y1 + dist * Math.sin(a)];
};
/**
 * Point on the line connecting A (@param x1, @param y1) and B (@param x2, @param y2) at distance (@param fraction * distance(A, B)) from A
 */
export const pointAtFractionDistance = (x1, y1, x2, y2, fraction) => {
  return pointAtDistance(x1, y1, x2, y2, fraction * distance(x1, y1, x2, y2));
};
/**
 * The quadrant where a point with relative coordinates (@param rx, @param ry) is with respect to @param rect
 */
export const rectQuadrant = (rx, ry, rect) => {
  return ((+(ry > (rect.height / rect.width) * rx)) << 1) + (+(Math.abs(ry) > (rect.height / rect.width) * Math.abs(rx)));
};
/**
 * The quadrant where a point with absolute coordinates (@param x, @param y) is with respect to @param rect
 */
export const rectQuadrantAbs = (x, y, rect) => {
  return rectQuadrant(x - rect.x, y - rect.y, rect);
};
/**
 * Intersection point between the shape of @param rect and the line connecting the rectangle center point and the point with absolute coordinates (@param x, @param y)
 */
export const rectProjection = (x, y, rect) => {
  let dx, dy;
  const quadrant = rectQuadrantAbs(x, y, rect);
  switch(quadrant) {
    case QUADRANT.TOP:
    case QUADRANT.BOTTOM:
      dy = (quadrant == QUADRANT.BOTTOM ? 1 : -1) * rect.height / 2;
      dx = (x - rect.x) / (y - rect.y) * dy;
      break;
    case QUADRANT.LEFT:
    case QUADRANT.RIGHT:
      dx = (quadrant == QUADRANT.RIGHT ? 1 : -1) * rect.width / 2;
      dy = (y - rect.y) / (x - rect.x) * dx;
      break;
  }
  return [rect.x + dx, rect.y + dy];
};
/**
 * Intersection point between the shape of @param roundrect and the line connecting the rectangle center point and the point with absolute coordinates (@param x, @param y)
 */
export const roundRectProjection = (x, y, roundRect) => {
  let dx, dy;
  const aMin = normalizeAngle(Math.atan2(roundRect.height / 2 - roundRect.radius, roundRect.width / 2));
  const aMax = normalizeAngle(Math.atan2(roundRect.height / 2, roundRect.width / 2 - roundRect.radius));
  const a = normalizeAngle(Math.atan2(roundRect.y - y, x - roundRect.x));
  const aMod = Math.atan2(Math.abs(Math.sin(a)), Math.abs(Math.cos(a)));
  const rectProj = rectProjection(x, y, { x: roundRect.x, y: roundRect.y, width: roundRect.width, height: roundRect.height });

  if(aMod < aMin || aMod > aMax)
    return rectProj;
  
  const rx = Math.abs(rectProj[0] - roundRect.x), ry = Math.abs(rectProj[1] - roundRect.y);
  const ra = Math.atan2(Math.abs(ry - (roundRect.height / 2 - roundRect.radius)), Math.abs(rx - (roundRect.width / 2 - roundRect.radius)));
  dx = (roundRect.width / 2 - roundRect.radius + (roundRect.radius * Math.cos(ra))) * Math.sign(Math.cos(a));
  dy = -(roundRect.height / 2 - roundRect.radius + (roundRect.radius * Math.sin(ra))) * Math.sign(Math.sin(a));
  return [roundRect.x + dx, roundRect.y + dy];
};
/**
 * Intersection point between the shape of @param diamond and the line connecting the diamond center point and the point with absolute coordinates (@param x, @param y)
 */
export const diamondProjection = (x, y, diamond) => {
  let dx, dy;
  const a = Math.atan2(Math.abs(y - diamond.y), Math.abs(x - diamond.x));
  dx = diamond.width / (2 + 2 * Math.tan(a) * diamond.width / diamond.height);
  dy = dx * Math.tan(a);
  if(x < diamond.x)
    dx = -dx;
  if(y < diamond.y)
    dy = -dy;
  return [diamond.x + dx, diamond.y + dy];
};
/**
 * Curve going through @param points, having an additional first point and last point
 */
export const multiPointCurve = (points, deltaAngle) => {
  let coords = [];
  
  for(let p of points)
    p.erAngle = normalizeAngle(Math.atan2(-p.y, p.x));
  points.sort((a, b) => a.erAngle - b.erAngle);

  const angleDist = points.map((a, i) => normalizeAngle(points[(i+1) % points.length].erAngle - a.erAngle));
  const angleDistMaxIndex = angleDist.indexOf(Math.max(...angleDist));
  points = points.slice(angleDistMaxIndex + 1).concat(points.slice(0, angleDistMaxIndex + 1));

  const firstAngle = points[0].erAngle - radians(deltaAngle);
  const firstLength = distanceFromOrigin(points[0].x, points[0].y);
  coords.push(firstLength * Math.cos(firstAngle), -firstLength * Math.sin(firstAngle));

  for(let p of points)
    coords.push(p.x, p.y);

  const lastAngle = points[points.length - 1].erAngle + radians(2 * deltaAngle);
  const lastLength = distanceFromOrigin(points[points.length - 1].x, points[points.length - 1].y);
  const x = lastLength * Math.cos(lastAngle), y = -lastLength * Math.sin(lastAngle);
  coords.push(x, y);

  return coords;
};