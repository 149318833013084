export default class GeneralizationChild {
  constructor(model, id, entityId, generalizationId) {
    this.__type = 'GeneralizationChild';
    this._model = () => model;
    this._id = id;
    this._entityId = entityId;
    this._generalizationId = generalizationId;
  }
  static fromObject(model, obj) {
    return new GeneralizationChild(model, obj._id, obj._entityId, obj._generalizationId);
  }
  __afterDelete() {
    if(this.getGeneralization().getChildren().length == 0)
      this._model().deleteItem(this.getGeneralization().getId());
  }
  getId() {
    return this._id;
  }
  getEntity() {
    return this._model().getItemById(this._entityId);
  }
  getGeneralization() {
    return this._model().getItemById(this._generalizationId);
  }
  getType() {
    return this.getGeneralization().getType();
  }
  getSupportedFunctionalities() {
    return {
      generalizationType: true,
      restructuring: true
    };
  }
  setType(type) {
    this.getGeneralization().setType(type);
  }
}