import colors from './colors';
import DiagramGroup from './diagramGroup';
import { rectProjection } from '../../utils/geometry';

const drawGeneralizationPath = (context, x1, y1, x2, y2, direct, horizontal, stroke, linewidth) => {
  let _path;
  if(direct)
    _path = context.two.makeLine(x1, y1, x2, y2);
  else if(horizontal)
    _path = context.two.makePath(x1, y1, x1, y2, x2, y2, x1, y2, x1, y1, false);
  else
    _path = context.two.makePath(x1, y1, x2, y1, x2, y2, x2, y1, x1, y1, false);
  _path.fill = colors.TRANSPARENT;
  _path.stroke = stroke || colors.BLACK;
  _path.linewidth = linewidth || 2;
  return _path;
};

const drawSelectionPath = (context, x1, y1, x2, y2, direct, horizontal) => drawGeneralizationPath(context, x1, y1, x2, y2, direct, horizontal, colors.TRANSPARENT, 12);

class GeneralizationChildGroup extends DiagramGroup {
  constructor(id, context) {
    super(id, context);
    this.erSegment = null;
    this.erPathContainer = this.context.two.makeGroup();
    this.add(this.erPathContainer);
  }
  setLine(_parent, anchorPoint, _child) {
    const horizontal = anchorPoint.y == 0;
    let x1 = _parent.translation.x + anchorPoint.x, y1 = _parent.translation.y + anchorPoint.y;
    let x2 = _child.translation.x, y2 = _child.translation.y;
    const direct = (horizontal ? Math.abs(y1 - y2) : Math.abs(x1 - x2)) < 15;
    [x2, y2] = rectProjection(direct || horizontal ? x1 : x2, direct || !horizontal ? y1 : y2, _child.getShape());
    this.erSegment = { anchorX: x1, anchorY: y1, entityX: x2, entityY: y2 };
    this.erPathContainer.remove(this.erPath).remove(this.erSelectionPath);
    this.erPath = drawGeneralizationPath(this.context, x1, y1, x2, y2, direct, horizontal);
    this.erSelectionPath = drawSelectionPath(this.context, x1, y1, x2, y2, direct, horizontal);
    this.erPathContainer.add(this.erPath).add(this.erSelectionPath);
  }
  setSelected(selected) {
    this.erPath.linewidth = selected ? 4 : 2;
  }
  setToBeRestructured(toBeRestructured) {
    this.erPath.stroke = toBeRestructured ? colors.RED : colors.BLACK;
  }
}

export const drawGeneralizationChild = (generalization, context) => {
  const scene = context.diagram.scene;
  let _generalization = context.diagram.$(generalization.getId());
  const _parent = context.diagram.$(generalization.getGeneralization().getEntity().getId());
  const _child = context.diagram.$(generalization.getEntity().getId());
  const _arrow = context.diagram.$(generalization.getGeneralization().getId());

  const selectedItemId = context.diagram.selectedItem?.getId();
  
  const restructuring = context.diagram.step == 'erRestructuring';

  if(!_generalization) {
    _generalization = new GeneralizationChildGroup(generalization.getId(), context);
    context.diagram.register(generalization.getId(), _generalization);
    scene.$modelGeneralizations.add(_generalization);
  }
  
  _generalization.setLine(_parent, _arrow.getAnchorPoint(), _child);
  _generalization.setSelected(selectedItemId == generalization.getId() || (restructuring && selectedItemId == generalization.getGeneralization().getId()));
  
  _generalization.setToBeRestructured(restructuring && generalization.getSupportedFunctionalities().restructuring);
};